import { Divider, Grid, makeStyles, Theme, Tooltip, Typography, Fade, Button, IconButton } from '@material-ui/core';
import { ExpandMoreRounded, InfoRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import React, { memo } from 'react';
import { TData, TListExpand } from 'pages/StockMovementPage';
import { GRAY_3, GREY, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import clsx from 'clsx';
import CreateBalanceSheetContainer from './CreateBalanceSheetContainer';
import NumberFormat from 'react-number-format';
import Loading from 'components/Loading';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const size = {
  small: 0.1,
  medium: 0.15,
  large: 0.25
} as const;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: '15vh'
  },
  titleTable: {
    whiteSpace: 'pre-wrap' /* or pre-line */,
    color: GRAY_3
  },
  fontSizeNormal: {
    fontSize: '.9rem',
    fontWeight: 'normal'
  },

  price: {
    fontSize: '.9rem',
    marginRight: '15px',
    fontWeight: 500
  },
  priceNormal: {
    fontSize: '.9rem',
    marginRight: '15px',
    fontWeight: 'normal'
  },

  title: {
    fontWeight: 500,
    fontSize: '16px'
  },

  fontBold: {
    fontSize: '.9rem',
    fontWeight: 500
  },
  padding: {
    padding: '1em 1em .8em'
  },

  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  itemWrapper: {
    border: `1px solid ${GREY}`,
    borderRadius: '.4em',
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 14%)'
    }
  },

  link: {
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  icon: {
    padding: '.15em',
    fontSize: '2rem',
    cursor: 'pointer',
    transition: 'rotate .3s',
    '&:hover': {
      background: GREY,
      borderRadius: '50%'
    }
  },
  tableWrapper: {
    backgroundColor: '#F7F9FC'
  },
  iconRotate: {
    rotate: '180deg'
  },
  skelaton: {
    height: '4em',
    background: GREY
  },
  empty: {
    background: GREY,
    borderRadius: '.4em'
  },
  positionRelative: {
    position: 'relative'
  },
  tooltipWrapper: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0px 2px 2px 1px rgb(0 0 0 / 20%)',
    zIndex: 1,
    padding: '1.5em'
  },
  contentInfo: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: 7,
      zIndex: 2,
      width: '1.3em',
      height: '1.3em',
      transform: 'translateX(-50%) rotate(45deg)',
      borderRight: '1px solid #9F9F9F',
      borderBottom: '1px solid #9F9F9F',
      backgroundColor: WHITE
    },
    '& > *': {
      color: '#000'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  colorGrey: {
    color: '#9F9F9F'
  },
  iconHover: {
    transition: 'all .3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#9F9F9F'
    }
  },
  minHight: {
    minHeight: '25em'
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export type TypeOperasionalResource = {
  isLoading: boolean;
  data: Operasional[];
};

export type TypeBalanceSheet = {
  isLoading: boolean;
  data: BalanceSheet;
};

/* Defining the props that the component will receive. */
interface Props {
  operasionalResource: TypeOperasionalResource;
  omset: boolean;
  setOmset: React.Dispatch<React.SetStateAction<boolean>>;
  purchase: boolean;
  setPurchase: React.Dispatch<React.SetStateAction<boolean>>;
  hpp: boolean;
  setHpp: React.Dispatch<React.SetStateAction<boolean>>;
  costOperational: boolean;
  setCostOperational: React.Dispatch<React.SetStateAction<boolean>>;
  balanceSheet: TData<BalanceSheet>;
  setKas: React.Dispatch<React.SetStateAction<boolean>>;
  kas: boolean;

  tradeReceivables: boolean;
  setTradeReceivables: React.Dispatch<React.SetStateAction<boolean>>;
  bankLoan: boolean;
  setBankLoan: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateIncomeStatementContent = ({
  operasionalResource,
  omset,
  setOmset,
  purchase,
  setPurchase,
  hpp,
  setHpp,
  kas,
  setKas,
  bankLoan,
  setBankLoan,
  balanceSheet,
  tradeReceivables,
  setTradeReceivables
}: Props) => {
  const classes = useStyles();

  return (
    <>
      {balanceSheet.isLoading ? (
        <FlexBox xs={12} container rowGap={1} className={clsx(classes.padding, classes.borderTop)}>
          <Grid xs={12} className={clsx(classes.tableWrapper, classes.minHight)} container alignItems='center' justify='center' direction='column'>
            <Loading />
            <Typography variant='h6' className={classes.fontSizeNormal}>
              Sedang Membuka...
            </Typography>
          </Grid>
        </FlexBox>
      ) : balanceSheet ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FlexBox container xs={12} rowGap={1} className={classes.container} alignItems='flex-start' style={{ paddingTop: 16 }}>
                <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
                  <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                    <Typography variant='h3' color={'initial'} className={clsx(classes.title)}>
                      ASET LANCAR - ACTIVA
                    </Typography>
                  </FlexBox>
                  <CreateBalanceSheetContainer isExpand={true}>
                    <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between' onClick={() => setKas(!kas)}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ExpandMoreRounded className={clsx(classes.icon, kas && classes.iconRotate)} />
                            <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                KAS
                              </Typography>
                            </FlexBox>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.totalCash < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalCash)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalCash} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                        {kas && (
                          <CreateBalanceSheetContainer isExpand={true}>
                            <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                              {balanceSheet.data.bank.map(data => (
                                <CreateBalanceSheetContainer isExpand={true}>
                                  <Grid xs={12}>
                                    <Divider />
                                  </Grid>

                                  <FlexBox alignItems='center' container justify='space-between'>
                                    <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                                      <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                        {data.name} - {data.number}
                                      </Typography>
                                      <Tooltip
                                        title={`Data ini berasal dari perhitungan: Kas (${Number(
                                          data.CashBalanceTotal
                                        ).toLocaleString()}) + Penjualan (${Number(data.invoice).toLocaleString()}) - Pembelian (${Number(
                                          data.purchase
                                        ).toLocaleString()}) + Transaksi Pemasukan Lain (${Number(
                                          data.debit
                                        ).toLocaleString()}) - Transaksi Pengeluaran Lain (${Number(data.credit).toLocaleString()}) - Beban (${Number(
                                          data.cost
                                        ).toLocaleString()})`}
                                        placement='top'
                                        classes={{ tooltip: classes.tooltip }}
                                        arrow
                                      >
                                        <IconButton size='small' style={{ padding: '1px', marginLeft: '5px' }}>
                                          <InfoOutlinedIcon fontSize='small' />
                                        </IconButton>
                                      </Tooltip>
                                    </FlexBox>
                                    <Typography variant='h6' className={classes.fontSizeNormal}>
                                      {data.total < 0 ? (
                                        <NumberFormat
                                          value={Math.abs(data.total)}
                                          prefix={'(Rp '}
                                          suffix={')'}
                                          thousandSeparator={true}
                                          displayType='text'
                                        />
                                      ) : (
                                        <NumberFormat value={data.total} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                                      )}
                                    </Typography>
                                  </FlexBox>
                                </CreateBalanceSheetContainer>
                              ))}
                            </FlexBox>
                          </CreateBalanceSheetContainer>
                        )}
                      </FlexBox>

                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <Typography variant='h6' className={clsx(classes.priceNormal)}>
                              PIUTANG DAGANG
                            </Typography>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.accountsReceivable < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.accountsReceivable)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.accountsReceivable} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>

                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                PERSEDIAAN AKHIR BARANG
                              </Typography>
                              <Tooltip
                                title={`Data ini berasal dari perhitungan: Total stok terakhir pada tanggal filter × Rata-rata harga pembelian`}
                                placement='top'
                                classes={{ tooltip: classes.tooltip }}
                                arrow
                              >
                                <IconButton size='small' style={{ padding: '1px', marginLeft: '5px' }}>
                                  <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                              </Tooltip>
                            </FlexBox>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.endingInventory < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.endingInventory)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.endingInventory} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                PIUTANG KARYAWAN - 130001
                              </Typography>
                            </FlexBox>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.receivablesEmployees < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.receivablesEmployees)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat
                                value={balanceSheet.data.receivablesEmployees}
                                prefix={'Rp '}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>

                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                PIUTANG GIRO PENJUALAN - 201001
                              </Typography>
                            </FlexBox>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.invoicePaymentGiroUnpaid < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.invoicePaymentGiroUnpaid)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat
                                value={balanceSheet.data.invoicePaymentGiroUnpaid}
                                prefix={'Rp '}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                PANJAR PEMBELIAN - 130002
                              </Typography>
                            </FlexBox>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.purchaseDeposit < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.purchaseDeposit)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.purchaseDeposit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL ASET LANCAR
                          </Typography>
                          <Typography variant='h6' className={classes.price}>
                            {balanceSheet.data.TotalCurrentAssets < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.TotalCurrentAssets)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.TotalCurrentAssets} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </CreateBalanceSheetContainer>
                </FlexBox>
              </FlexBox>
              {/* Aset Tetap (Activa) */}
              <FlexBox container xs={12} rowGap={1} className={classes.container} alignItems='flex-start' style={{ paddingTop: 16 }}>
                <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
                  <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                    <Typography variant='h3' color={'initial'} className={clsx(classes.title)}>
                      ASET TETAP - ACTIVA
                    </Typography>
                  </FlexBox>
                  <CreateBalanceSheetContainer isExpand={true}>
                    <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                      {balanceSheet.data.asset.map(data => (
                        <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                          <FlexBox alignItems='center' container justify='space-between'>
                            <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                              <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                                <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                  {data.name} - {data.number}
                                </Typography>
                                <Tooltip
                                  title={`Data ini berasal dari perhitungan: Total Transaksi Pembelian Penjualan (${Number(
                                    data.debit
                                  ).toLocaleString()})  - Total Transaksi Pembelian (${Number(data.credit).toLocaleString()}) `}
                                  placement='top'
                                  classes={{ tooltip: classes.tooltip }}
                                  arrow
                                >
                                  <IconButton size='small' style={{ padding: '1px', marginLeft: '5px' }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </FlexBox>
                            </div>
                            <Typography variant='h6' className={classes.priceNormal}>
                              {data.total < 0 ? (
                                <NumberFormat value={Math.abs(data.total)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
                              ) : (
                                <NumberFormat value={data.total} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                              )}
                            </Typography>
                          </FlexBox>
                        </FlexBox>
                      ))}
                      {/* <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                        <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                          <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                            Bangunan - 1801
                          </Typography>
                          <Tooltip
                            title={`Data ini berasal dari perhitungan: Total Transaksi Pembelian Kendaraan`}
                            placement='top'
                            classes={{ tooltip: classes.tooltip }}
                            arrow
                          >
                            <IconButton size='small' style={{ padding: '1px', marginLeft: '5px' }}>
                              <InfoOutlinedIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </FlexBox>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {balanceSheet.data.buildings < 0 ? (
                          <NumberFormat
                            value={Math.abs(balanceSheet.data.buildings)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={balanceSheet.data.buildings} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                        <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                          <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                            Peralatan dan Mesin - 1802
                          </Typography>
                          <Tooltip
                            title={`Data ini berasal dari perhitungan: Total Transaksi Pembelian Bangunan`}
                            placement='top'
                            classes={{ tooltip: classes.tooltip }}
                            arrow
                          >
                            <IconButton size='small' style={{ padding: '1px', marginLeft: '5px' }}>
                              <InfoOutlinedIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </FlexBox>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {balanceSheet.data.equipmentAndMachinery < 0 ? (
                          <NumberFormat
                            value={Math.abs(balanceSheet.data.equipmentAndMachinery)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={balanceSheet.data.equipmentAndMachinery} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                        <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                          <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                            Kendaraan - 1803
                          </Typography>
                          <Tooltip
                            title={`Data ini berasal dari perhitungan: Total Transaksi Pembelian Peralatan dan Mesin`}
                            placement='top'
                            classes={{ tooltip: classes.tooltip }}
                            arrow
                          >
                            <IconButton size='small' style={{ padding: '1px', marginLeft: '5px' }}>
                              <InfoOutlinedIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </FlexBox>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {balanceSheet.data.vehicles < 0 ? (
                          <NumberFormat
                            value={Math.abs(balanceSheet.data.vehicles)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={balanceSheet.data.vehicles} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox> */}

                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL ASET TETAP
                          </Typography>
                          <Typography variant='h6' className={classes.price}>
                            {balanceSheet.data.totalAsetTetap < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalAsetTetap)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalAsetTetap} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </CreateBalanceSheetContainer>
                </FlexBox>
              </FlexBox>

              <FlexBox container xs={12} rowGap={1} className={classes.container} alignItems='flex-start' style={{ paddingTop: 16 }}>
                <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
                  <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                    <Typography variant='h3' color={'initial'} className={clsx(classes.title)}>
                      TOTAL ACTIVA
                    </Typography>
                  </FlexBox>
                  <CreateBalanceSheetContainer isExpand={true}>
                    <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <Typography variant='h6' className={clsx(classes.priceNormal)}>
                              TOTAL ASET TETAP
                            </Typography>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.totalAsetTetap < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalAsetTetap)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalAsetTetap} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <Typography variant='h6' className={clsx(classes.priceNormal)}>
                              TOTAL ASET LANCAR
                            </Typography>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.TotalCurrentAssets < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.TotalCurrentAssets)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.TotalCurrentAssets} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL ACTIVA
                          </Typography>
                          <Typography variant='h6' className={classes.price}>
                            {balanceSheet.data.totalActiva < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalActiva)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalActiva} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </CreateBalanceSheetContainer>
                </FlexBox>
              </FlexBox>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
              <FlexBox container xs={12} rowGap={1} className={classes.container} alignItems='flex-start' style={{ paddingTop: 16 }}>
                <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
                  <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                    <Typography variant='h3' color={'initial'} className={clsx(classes.title)}>
                      LIABILITAS - PASSIVA
                    </Typography>
                  </FlexBox>
                  <CreateBalanceSheetContainer isExpand={true}>
                    <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between' onClick={() => setOmset(!omset)}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ExpandMoreRounded className={clsx(classes.icon, omset && classes.iconRotate)} />
                            <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                HUTANG USAHA
                              </Typography>
                            </FlexBox>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.totalLoan < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalLoan)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalLoan} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                        {omset && (
                          <CreateBalanceSheetContainer isExpand={true}>
                            <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                              {balanceSheet.data.loan.map(data => (
                                <CreateBalanceSheetContainer isExpand={true}>
                                  <Grid xs={12}>
                                    <Divider />
                                  </Grid>
                                  <FlexBox alignItems='center' container justify='space-between'>
                                    <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                                      <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                        {data.name} - {data.number}
                                      </Typography>
                                    </FlexBox>
                                    <Typography variant='h6' className={classes.fontSizeNormal}>
                                      {data.total < 0 ? (
                                        <NumberFormat
                                          value={Math.abs(data.total)}
                                          prefix={'(Rp '}
                                          suffix={')'}
                                          thousandSeparator={true}
                                          displayType='text'
                                        />
                                      ) : (
                                        <NumberFormat value={data.total} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                                      )}
                                    </Typography>
                                  </FlexBox>
                                </CreateBalanceSheetContainer>
                              ))}
                            </FlexBox>
                          </CreateBalanceSheetContainer>
                        )}
                      </FlexBox>

                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between' onClick={() => setBankLoan(!bankLoan)}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ExpandMoreRounded className={clsx(classes.icon, bankLoan && classes.iconRotate)} />
                            <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                HUTANG BANK
                              </Typography>
                            </FlexBox>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.bankLoan < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.bankLoan)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.bankLoan} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                        {bankLoan && (
                          <CreateBalanceSheetContainer isExpand={true}>
                            <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                              {balanceSheet.data.bankLoanResult.map(data => (
                                <CreateBalanceSheetContainer isExpand={true}>
                                  <Grid xs={12}>
                                    <Divider />
                                  </Grid>
                                  <FlexBox alignItems='center' container justify='space-between'>
                                    <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                                      <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                        {data.name} - {data.number}
                                      </Typography>
                                    </FlexBox>
                                    <Typography variant='h6' className={classes.fontSizeNormal}>
                                      {data.total < 0 ? (
                                        <NumberFormat
                                          value={Math.abs(data.total)}
                                          prefix={'(Rp '}
                                          suffix={')'}
                                          thousandSeparator={true}
                                          displayType='text'
                                        />
                                      ) : (
                                        <NumberFormat value={data.total} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                                      )}
                                    </Typography>
                                  </FlexBox>
                                </CreateBalanceSheetContainer>
                              ))}
                            </FlexBox>
                          </CreateBalanceSheetContainer>
                        )}
                      </FlexBox>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                PANJAR PENJUALAN - 202001
                              </Typography>
                            </FlexBox>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.salesDeposit < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.salesDeposit)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.salesDeposit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>

                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL LIABILITAS
                          </Typography>
                          <Typography variant='h6' className={classes.price}>
                            {balanceSheet.data.totalLiabilities < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalLiabilities)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalLiabilities} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </CreateBalanceSheetContainer>
                </FlexBox>
              </FlexBox>

              <FlexBox container xs={12} rowGap={1} className={classes.container} alignItems='flex-start' style={{ paddingTop: 16 }}>
                <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
                  <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                    <Typography variant='h3' color={'initial'} className={clsx(classes.title)}>
                      EKUITAS - PASSIVA
                    </Typography>
                  </FlexBox>
                  <CreateBalanceSheetContainer isExpand={true}>
                    <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                      {balanceSheet.data.modal.map(data => (
                        <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                          <FlexBox alignItems='center' container justify='space-between'>
                            <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                              <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                                <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                                  {data.name} - {data.number}
                                </Typography>
                                <Tooltip
                                  title={`Data ini berasal dari perhitungan: Total Transaksi Modal`}
                                  placement='top'
                                  classes={{ tooltip: classes.tooltip }}
                                  arrow
                                >
                                  <IconButton size='small' style={{ padding: '1px', marginLeft: '5px' }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                  </IconButton>
                                </Tooltip>
                              </FlexBox>
                            </div>
                            <Typography variant='h6' className={classes.priceNormal}>
                              {data.total < 0 ? (
                                <NumberFormat value={Math.abs(data.total)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
                              ) : (
                                <NumberFormat value={data.total} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                              )}
                            </Typography>
                          </FlexBox>
                        </FlexBox>
                      ))}

                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL EKUITAS
                          </Typography>
                          <Typography variant='h6' className={classes.price}>
                            {balanceSheet.data.totalModal < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalModal)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalModal} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </CreateBalanceSheetContainer>
                </FlexBox>
              </FlexBox>
              {/* end Activa*/}
              <FlexBox container xs={12} rowGap={1} className={classes.container} alignItems='flex-end' style={{ paddingTop: 16, flexGrow: 1 }}>
                <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
                  <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                    <Typography variant='h3' color={'initial'} className={clsx(classes.title)}>
                      TOTAL PASSIVA
                    </Typography>
                  </FlexBox>
                  <CreateBalanceSheetContainer isExpand={true}>
                    <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <Typography variant='h6' className={clsx(classes.priceNormal)}>
                              TOTAL LIABILITAS
                            </Typography>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.totalLiabilities < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalLiabilities)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalLiabilities} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                            <Typography variant='h6' className={clsx(classes.priceNormal)}>
                              TOTAL EKUITAS
                            </Typography>
                          </div>
                          <Typography variant='h6' className={classes.priceNormal}>
                            {balanceSheet.data.totalEkuitas < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalEkuitas)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalEkuitas} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox alignItems='center' container justify='space-between'>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL PASSIVA
                          </Typography>
                          <Typography variant='h6' className={classes.price}>
                            {balanceSheet.data.totalPassiva < 0 ? (
                              <NumberFormat
                                value={Math.abs(balanceSheet.data.totalPassiva)}
                                prefix={'(Rp '}
                                suffix={')'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            ) : (
                              <NumberFormat value={balanceSheet.data.totalPassiva} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                            )}
                          </Typography>
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </CreateBalanceSheetContainer>
                </FlexBox>
              </FlexBox>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid xs={12} container justify='center' alignItems='center' className={clsx(classes.minHight, classes.borderTop)}>
          <Typography variant='h6'>Tidak ada data.</Typography>
        </Grid>
      )}
    </>
  );
};

export default memo(CreateIncomeStatementContent);
