import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { Container, Grid, makeStyles, Typography, Theme } from '@material-ui/core';
import axios from 'axios';
import clsx from 'clsx';
import { Breadcrumb, Page, PaperCustom } from 'components';
import FlexBox from 'components/FlexBox';
import { CATEGORY_BASE_URL, PRODUCT_SEARCH, STOCK_STATISTIC_BASE_URL, ZONE_BASE_URL, WAREHOUSE_BASE_URL } from 'constants/url';
import useDebounce from 'hooks/useDebounce';
import { dummyMetaData } from 'utils/dummy';
import { StatisticStockFilters, StatisticStockHeader } from './components';
import PaginationCustom from 'components/PaginationCustom';
import { format } from 'date-fns';
import monthNames from 'utils/month';
import { GREY } from 'constants/colors';
import logo from 'images/amico.svg';
const StatisticStockContent = lazy(() => import('./components/StatisticStockContent'));
export type TListExpand = {
  id: number | string;
  action: 'products' | 'months';
};

export type TFilters = {
  keyWord: string;
  productName: string;
  categoryId: number;
  zoneId: number;
  warehouseId: number;
  id: number;
  startDate: string;
  endDate: string;
};

export type TData<T> = {
  isLoading: boolean;
  data: T;
};

/* Defining a type called TFilters and then creating a constant called dummyFilters that is of type
TFilters. */
const currentYear = new Date().getFullYear();
const startOfYear = new Date(currentYear, 0, 1);
const today = new Date();
export const dummyFilters: TFilters = {
  productName: '',
  keyWord: '',
  categoryId: 0,
  zoneId: 0,
  warehouseId: 0,
  id: 0,
  startDate: format(startOfYear, 'yyyy-MM'),
  endDate: format(today, 'yyyy-MM')
};

const dummyData = {
  isLoading: true,
  data: []
};

const dummySummary: stockMovementSummary = {
  totalStockFirstStart: 0,
  totalStockDamageStart: 0,
  totalStockSalesStart: 0,
  totalStockEnd: 0,
  totalStockDamageEnd: 0,
  totalStockSalesEnd: 0
};

const useStyles = makeStyles((theme: Theme) => ({
  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  minHight: {
    minHeight: '25em'
  },
  logo: {
    width: '400px',
    height: '337px',
    marginTop: '2em'
  }
}));

const CashHistoryDetailPage = () => {
  const classes = useStyles();
  const [filters, setFilters] = useState<TFilters>(dummyFilters);
  const [products, setProducts] = useState<TData<StockMovementProduct[]>>(dummyData);
  const [productMonths, setProductMonths] = useState<TData<StockMovementProductMonth[]>>(dummyData);
  const [productSuggests, setProductSuggests] = useState<{ productName: string; id: number; wareHouse: string }[]>([]);
  const [productId, setProductId] = useState<number>(0);
  const [productCategories, setProductCategories] = useState<TData<CategoryModel[]>>(dummyData);
  const [zones, setZones] = useState<TData<ZoneModel[]>>(dummyData);
  const [warehouses, setWarehouses] = useState<TData<WareHouseModel[]>>(dummyData);
  const [productMeta, setProductMeta] = useState<MetaData>(dummyMetaData);
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [stockMovements, setStockMovements] = useState<TData<StockStatistic[]>>(dummyData);
  const [stockMovementSummary, setStockMovementSummary] = useState<TData<stockMovementSummary>>({
    isLoading: dummyData.isLoading,
    data: dummySummary
  });

  const [productExpand, setProductExpand] = useState<{ id: number; isOpen: boolean }>({
    id: 0,
    isOpen: false
  });
  const [monthExpand, setMonthExpand] = useState<{ month: string; isOpen: boolean }>({
    month: '',
    isOpen: false
  });
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(startOfYear);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [formattedEndDate, setFormattedEndDate] = useState<string>(format(selectedEndDate, 'yyyy-MM'));
  const [formattedStartDate, setFormattedStartDate] = useState<string>(format(selectedStartDate, 'yyyy-MM'));
  const [loadProduct, setLoadProduct] = useState<boolean>(false);
  const [isShowSuggest, setIsShowSuggest] = React.useState(false);

  useEffect(() => {
    setFormattedEndDate(format(selectedEndDate, 'yyyy-MM'));
    setFormattedStartDate(format(selectedStartDate, 'yyyy-MM'));
  }, [selectedEndDate]);

  const handleChangeFilters = <T,>(key: keyof typeof dummyFilters, value: T) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const handleClickSuggest = (productName: string, id: number) => {
    handleChangeFilters('id', id);
    handleChangeFilters('keyWord', productName);
    handleChangeFilters('productName', productName);
    handleChangeFilters('categoryId', 0);
    setProductMeta(prev => ({ ...prev, current_page: 1 }));
    setProductSuggests([]);
    setIsShowSuggest(false);
  };

  const handleSearchPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleChangeFilters('productName', filters.keyWord);
      setProductMeta(prev => ({ ...prev, current_page: 1 }));
      setProductSuggests([]);
    }
  };

  const handleClearKeyWord = () => {
    handleChangeFilters('keyWord', '');
    handleChangeFilters('id', '');
    handleChangeFilters('productName', '');
    setProductSuggests([]);
  };

  const fetchProductSuggests = async () => {
    const params = new URLSearchParams();
    params.append('keyword', filters.keyWord);
    setLoadProduct(true);
    try {
      const { data } = await axios.get(`${PRODUCT_SEARCH}?${params}`, {
        cancelToken
      });
      setProductSuggests(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadProduct(false);
    }
  };

  const fetchProductCategory = async () => {
    setProductCategories(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params}`, {
        cancelToken
      });
      setProductCategories(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchZone = async () => {
    setZones(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${params}`, {
        cancelToken
      });
      setZones(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchWareHouses = async () => {
    setWarehouses(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    try {
      const { data } = await axios.get(`${WAREHOUSE_BASE_URL}?${params}`, {
        cancelToken
      });
      setWarehouses(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    setProductMeta(prev => ({ ...prev, current_page: page }));
  };

  const handleRefresh = () => {
    setFilters(dummyFilters);
    fetchProducts();
    setProductMeta(prev => ({ ...prev, current_page: 1 }));
  };

  const handleDownload = async () => {
    setIsLoadingDownload(true);
    try {
      console.log('masuk sini');
      const params = new URLSearchParams();

      if (formattedStartDate !== null) {
        params.append('startDate', filters.startDate.toString());
      }
      if (formattedEndDate !== null) {
        params.append('endDate', filters.endDate.toString());
      }

      if (filters.warehouseId !== 0) {
        params.append('warehouseId', filters.warehouseId.toString());
      }
      if (filters.zoneId !== 0) {
        params.append('zoneId', filters.zoneId.toString());
      }
      if (filters.id !== 0) {
        params.append('productId', filters.id.toString());
      }
      const { data } = await axios.get(`${STOCK_STATISTIC_BASE_URL}/export?${params}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `Invoice-Penjualan-${format(new Date(), 'dd/MM/yyyy-HH:mm:ss')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      // setTimeout(() => setIsLoadingDownload(false), 3000);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const fetchProducts = async () => {
    setProducts(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    params.append('page', productMeta.current_page.toString());

    if (formattedStartDate !== null) {
      params.append('startDate', filters.startDate.toString());
    }
    if (formattedEndDate !== null) {
      params.append('endDate', filters.endDate.toString());
    }
    if (filters.warehouseId !== 0) {
      params.append('warehouseId', filters.warehouseId.toString());
    }
    if (filters.zoneId !== 0) {
      params.append('zoneId', filters.zoneId.toString());
    }
    if (filters.id !== 0) {
      params.append('productId', filters.id.toString());
    } else {
      setProductMonths(prev => ({
        ...prev,
        isLoading: false
      }));
      return;
    }

    try {
      const { data } = await axios.get(`${STOCK_STATISTIC_BASE_URL}?${params}`, {
        cancelToken
      });
      setProducts({
        isLoading: false,
        data: data.data.map((product: StockMovementProduct) => {
          return { ...product, hasExpand: false };
        })
      });

      setProductMeta(prev => ({ ...prev, ...data.meta }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductMonths = async () => {
    setProductMonths(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    params.append('page', productMeta.current_page.toString());

    if (formattedStartDate !== null) {
      params.append('startDate', filters.startDate.toString());
    }
    if (formattedEndDate !== null) {
      params.append('endDate', filters.endDate.toString());
    }

    if (filters.warehouseId !== 0) {
      params.append('warehouseId', filters.warehouseId.toString());
    }
    if (filters.zoneId !== 0) {
      params.append('zoneId', filters.zoneId.toString());
    }
    if (filters.id !== 0) {
      params.append('productId', filters.id.toString());
    } else {
      setProductMonths(prev => ({
        ...prev,
        isLoading: false
      }));
      return;
    }

    try {
      const { data } = await axios.get(`${STOCK_STATISTIC_BASE_URL}?${params}`, {
        cancelToken
      });
      setProductMonths(prev => ({
        isLoading: false,
        data: data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStockMovement = async (date: string) => {
    setStockMovements(prev => ({ ...prev, isLoading: true }));

    const params = new URLSearchParams();

    params.append('date', date);

    if (formattedStartDate !== null) {
      params.append('startDate', filters.startDate.toString());
    }
    if (formattedEndDate !== null) {
      params.append('endDate', filters.endDate.toString());
    }
    if (filters.warehouseId !== 0) {
      params.append('warehouseId', filters.warehouseId.toString());
    }
    if (filters.zoneId !== 0) {
      params.append('zoneId', filters.zoneId.toString());
    }
    if (filters.id !== 0) {
      params.append('productId', filters.id.toString());
    } else {
      setProductMonths(prev => ({
        ...prev,
        isLoading: false
      }));
      return;
    }

    try {
      const { data } = await axios.get(`${STOCK_STATISTIC_BASE_URL}/detail?${params}`, {
        cancelToken
      });
      setStockMovements(prev => ({
        ...prev,
        isLoading: false,
        data: data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchProductMonths();
    return () => source.cancel('Cancel Unmounted');
  }, [productMeta.current_page, filters.productName, filters.categoryId, filters.zoneId, filters.warehouseId, filters.startDate, filters.endDate]);

  const productNameDebouce = useDebounce(filters.keyWord, 500);

  useEffect(() => {
    if (productNameDebouce.length < 1 || filters.productName === filters.keyWord) return;
    fetchProductSuggests();
    return () => source.cancel('Cancel Unmounted');
  }, [productNameDebouce]);

  const handleListExpand = useCallback(
    ({ id, action }: TListExpand) => {
      if (action.includes('products')) {
        setProducts(prev => ({
          ...prev,
          data: prev.data.map(product => {
            return { ...product, hasExpand: product.id === id };
          })
        }));

        setProductExpand(prev => ({
          id: Number(id),
          isOpen: prev.id === id ? !prev.isOpen : true
        }));

        if (products.data.filter(product => product.id === id)[0].hasExpand) return;
        fetchProductMonths();
      } else {
        setMonthExpand(prev => ({
          month: String(id),
          isOpen: prev.month === id ? !prev.isOpen : true
        }));

        const date = +id.toString().slice(5) < 10 ? `${new Date(id.toString()).getFullYear()}-0${id.toString().slice(5)}` : String(id);

        fetchStockMovement(date);
      }
    },
    [products, productMonths, stockMovements]
  );

  const handleOnClickHeader = {
    isLoadingDownload,
    handleDownload,
    handleRefresh
  };

  useEffect(() => {
    fetchProductCategory();
    fetchZone();
    fetchWareHouses();
  }, []);

  return (
    <Page title='Statistik Stok'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'>Statistik Stok</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <FlexBox container rowGap={1}>
            <StatisticStockFilters
              filters={filters}
              handleChangeFilters={handleChangeFilters}
              productSuggests={productSuggests}
              zones={zones}
              loadProduct={loadProduct}
              isShowSuggest={isShowSuggest}
              setIsShowSuggest={setIsShowSuggest}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedStartDate={setSelectedStartDate}
              setSelectedEndDate={setSelectedEndDate}
              handleClickSuggest={handleClickSuggest}
              handleClearKeyWord={handleClearKeyWord}
              handleSearchPress={handleSearchPress}
            />
            {filters.id !== 0 ? (
              <>
                <StatisticStockHeader
                  titleNode1={<Typography variant='h6'>Menampilkan seluruh riwayat harga item</Typography>}
                  titleNode2={
                    <Typography>
                      Data bulan {`${monthNames[selectedStartDate.getMonth()]} ${selectedStartDate.getFullYear()}`} s/d{' '}
                      {`${monthNames[selectedEndDate.getMonth()]} ${selectedEndDate.getFullYear()}`}
                    </Typography>
                  }
                  onClick={handleOnClickHeader}
                />
                <Suspense
                  fallback={
                    <Grid xs={12} container justify='center' alignItems='center'>
                      <Typography variant='h6'>Sedang Membuka...</Typography>
                    </Grid>
                  }
                >
                  <StatisticStockContent
                    products={products}
                    stockMovements={stockMovements}
                    handleListExpand={handleListExpand}
                    productMonths={productMonths}
                    productExpand={productExpand}
                    monthExpand={monthExpand}
                  />
                </Suspense>
                {productMonths.data.length != 0 ? (
                  <Grid xs={12} justify='flex-end' container>
                    <PaginationCustom
                      marginTop='-.4em '
                      show={true}
                      sxPagination={{
                        count: productMeta.last_page,
                        boundaryCount: 2,
                        variant: 'outlined',
                        shape: 'rounded',
                        onChange: (event, page) => {
                          setProductMeta(prev => ({ ...prev, current_page: page }));
                        },
                        page: productMeta.current_page
                      }}
                      sxPopover={{
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right'
                        },
                        transformOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right'
                        }
                      }}
                      customPageProps={{
                        defaultValue: productMeta.current_page,
                        maxValue: productMeta.last_page,
                        onSubmit(value) {
                          setProductMeta(prev => ({ ...prev, current_page: value }));
                        }
                      }}
                    />
                  </Grid>
                ) : null}
              </>
            ) : (
              <Grid xs={12} container justify='center' alignItems='center' direction='column' className={clsx(classes.minHight)}>
                <img src={logo} alt='' className={classes.logo} />
                <Typography variant='h6' style={{ fontSize: '24px' }} align='center'>
                  Ooops, silahkan filter dahulu
                </Typography>
                <Typography>Anda harus mengisi filter untuk melihat statistik stok</Typography>
              </Grid>
            )}
          </FlexBox>
        </PaperCustom>
      </Container>
    </Page>
  );
};
export default CashHistoryDetailPage;
