import { Divider, Grid, makeStyles, Theme, Tooltip, Typography, IconButton } from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import React, { memo } from 'react';
import { TData } from 'pages/StockMovementPage';
import { GRAY_3, GREY, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import clsx from 'clsx';
import CreateIncomeStatementContainer from './CreateIncomeStatementContainer';
import NumberFormat from 'react-number-format';
import Loading from 'components/Loading';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const size = {
  small: 0.1,
  medium: 0.15,
  large: 0.25
} as const;

type TTableProperty = {
  key: keyof StockMovement;
  title: string;
  size: keyof typeof size;
  align: 'left' | 'center' | 'right';
};
const tableProperties: TTableProperty[] = [
  {
    key: 'createdAt',
    title: 'Tanggal',
    size: 'medium',
    align: 'left'
  },
  {
    key: 'activity',
    title: 'Aktifitas\nNo.Transaksi',
    size: 'large',
    align: 'left'
  },

  {
    key: 'firstStock',
    title: 'Total Stok',
    size: 'small',
    align: 'right'
  },
  {
    key: 'mutation',
    title: 'Stok Mutasi',
    size: 'small',
    align: 'right'
  },
  {
    key: 'holdStock',
    title: 'Stok Tertahan',
    size: 'small',
    align: 'right'
  },

  {
    key: 'damageStock',
    title: 'Stok Rusak',
    size: 'small',
    align: 'right'
  },
  {
    key: 'salesStock',
    title: 'Stok\nJual',
    size: 'small',
    align: 'right'
  },
  {
    key: 'price',
    title: 'Harga Beli/Jual',
    size: 'medium',
    align: 'right'
  },
  {
    key: 'nettHpp',
    title: 'Nett Hpp',
    size: 'medium',
    align: 'right'
  },
  {
    key: 'totalNettHpp',
    title: 'Total\nNett Hpp',
    size: 'medium',
    align: 'right'
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: '5vh'
  },
  titleTable: {
    whiteSpace: 'pre-wrap' /* or pre-line */,
    color: GRAY_3
  },
  fontSizeNormal: {
    fontSize: '.9rem',
    fontWeight: 'normal'
  },

  price: {
    fontSize: '.9rem',
    marginRight: '15px',
    fontWeight: 500
  },
  priceNormal: {
    fontSize: '.9rem',
    marginRight: '15px',
    fontWeight: 'normal'
  },

  title: {
    fontWeight: 500,
    fontSize: '16px'
  },

  fontBold: {
    fontSize: '.9rem',
    fontWeight: 500
  },
  padding: {
    padding: '1em 1em .8em'
  },

  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  itemWrapper: {
    border: `1px solid ${GREY}`,
    borderRadius: '.4em',
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 14%)'
    }
  },

  link: {
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  icon: {
    padding: '.15em',
    fontSize: '2rem',
    cursor: 'pointer',
    transition: 'rotate .3s',
    '&:hover': {
      background: GREY,
      borderRadius: '50%'
    }
  },
  tableWrapper: {
    backgroundColor: '#F7F9FC'
  },
  iconRotate: {
    rotate: '180deg'
  },
  skelaton: {
    height: '4em',
    background: GREY
  },
  empty: {
    background: GREY,
    borderRadius: '.4em'
  },
  positionRelative: {
    position: 'relative'
  },
  tooltipWrapper: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0px 2px 2px 1px rgb(0 0 0 / 20%)',
    zIndex: 1,
    padding: '1.5em'
  },
  contentInfo: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: 7,
      zIndex: 2,
      width: '1.3em',
      height: '1.3em',
      transform: 'translateX(-50%) rotate(45deg)',
      borderRight: '1px solid #9F9F9F',
      borderBottom: '1px solid #9F9F9F',
      backgroundColor: WHITE
    },
    '& > *': {
      color: '#000'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  colorGrey: {
    color: '#9F9F9F'
  },
  iconHover: {
    transition: 'all .3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#9F9F9F'
    }
  },
  minHight: {
    minHeight: '25em'
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));

export type TypeOperasionalResource = {
  isLoading: boolean;
  data: any[];
};

/* Defining the props that the component will receive. */
interface Props {
  operasionalResource: TypeOperasionalResource;
  otherIncomeCategory: TypeOperasionalResource;
  omset: boolean;
  setOmset: React.Dispatch<React.SetStateAction<boolean>>;
  purchase: boolean;
  setPurchase: React.Dispatch<React.SetStateAction<boolean>>;
  hpp: boolean;
  setHpp: React.Dispatch<React.SetStateAction<boolean>>;
  costOperational: boolean;
  setCostOperational: React.Dispatch<React.SetStateAction<boolean>>;
  incomeStatement: TData<IncomeStatement>;
  otherIncomeCategoryExpand: boolean;
  setOtherIncomeCategoryExpand: React.Dispatch<React.SetStateAction<boolean>>;
  otherExpensesCategory: TypeOperasionalResource;
  setOtherExpensesCategoryExpand: React.Dispatch<React.SetStateAction<boolean>>;
  otherExpensesCategoryExpand: boolean;
}

const CreateIncomeStatementContent = ({
  operasionalResource,
  otherIncomeCategory,
  omset,
  setOmset,
  purchase,
  setPurchase,
  hpp,
  setHpp,
  costOperational,
  setCostOperational,
  otherIncomeCategoryExpand,
  setOtherIncomeCategoryExpand,
  otherExpensesCategory,
  setOtherExpensesCategoryExpand,
  otherExpensesCategoryExpand,
  incomeStatement
}: Props) => {
  const classes = useStyles();

  return (
    <>
      {incomeStatement.isLoading ? (
        <FlexBox xs={12} container rowGap={1} className={clsx(classes.padding, classes.borderTop)}>
          <Grid xs={12} className={clsx(classes.tableWrapper, classes.minHight)} container alignItems='center' justify='center' direction='column'>
            <Loading />
            <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
              Sedang Membuka...
            </Typography>
          </Grid>
        </FlexBox>
      ) : incomeStatement ? (
        <>
          <FlexBox container xs={12} className={classes.container} alignItems='flex-start'>
            <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
              {' '}
              <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                <Typography color={'initial'} className={clsx(classes.title)}>
                  OMSET PENJUALAN
                </Typography>
              </FlexBox>
              <CreateIncomeStatementContainer isExpand={true}>
                <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between' onClick={() => setOmset(!omset)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ExpandMoreRounded className={clsx(classes.icon, omset && classes.iconRotate)} />
                        <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                          PENJUALAN BERSIH
                        </Typography>
                      </div>

                      <Typography variant='h6' className={classes.priceNormal}>
                        <NumberFormat value={incomeStatement.data.netRevenue} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                      </Typography>
                    </FlexBox>
                    {omset && (
                      <CreateIncomeStatementContainer isExpand={true}>
                        <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                PENJUALAN KOTOR
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.totalSales < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.totalSales)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat value={incomeStatement.data.totalSales} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                TAGIHAN RETURN PENJUALAN
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.salesReturnBill < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.salesReturnBill)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.salesReturnBill}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                DISKON PENJUALAN
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.salesDiscount < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.salesDiscount)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.salesDiscount}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                RETURN PENJUALAN
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.salesReturn < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.salesReturn)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat value={incomeStatement.data.salesReturn} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                POTONGAN PENJUALAN LAIN LAIN
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.invoicePiece < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.invoicePiece)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.invoicePiece}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                        </FlexBox>
                      </CreateIncomeStatementContainer>
                    )}
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '5px', alignItems: 'center' }}>
                        <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL OMSET PENJUALAN BERSIH
                          </Typography>
                          <Tooltip
                            title={`Data ini berasal dari perhitungan: Penjualan Barang + Tagihan Return Penjualan - Diskon Penjualan - Return Penjualan - Potongan Penjualan Lain Lain.`}
                            placement='top'
                            classes={{ tooltip: classes.tooltip }}
                            arrow
                          >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </FlexBox>
                      </div>

                      <Typography variant='h6' className={classes.price}>
                        {incomeStatement.data.netRevenue < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.netRevenue)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.netRevenue} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CreateIncomeStatementContainer>
            </FlexBox>
          </FlexBox>
          {/* pembelian */}
          <FlexBox container xs={12} className={classes.container} alignItems='flex-start'>
            <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
              <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                <Typography variant='h6' color={'initial'} className={clsx(classes.title)}>
                  PEMBELIAN
                </Typography>
              </FlexBox>

              <CreateIncomeStatementContainer isExpand={true}>
                <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between' onClick={() => setPurchase(!purchase)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ExpandMoreRounded className={clsx(classes.icon, purchase && classes.iconRotate)} />
                        <Typography variant='h6' className={clsx(classes.fontSizeNormal)}>
                          PEMBELIAN BERSIH
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.totalPurchaseCost < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.totalPurchaseCost)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.totalPurchaseCost} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                    {purchase && (
                      <CreateIncomeStatementContainer isExpand={true}>
                        <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                PEMBELIAN KOTOR
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.totalPurchase < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.totalPurchase)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.totalPurchase}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                DISKON PEMBELIAN
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.purchaseInvoiceDiscount < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.purchaseInvoiceDiscount)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.purchaseInvoiceDiscount}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                RETURN PEMBELIAN
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.purchaseReturn < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.purchaseReturn)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.purchaseReturn}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                POTONGAN PEMBELIAN LAIN LAIN
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.purchaseInvoicePiece < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.purchaseInvoicePiece)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.purchaseInvoicePiece}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                        </FlexBox>
                      </CreateIncomeStatementContainer>
                    )}
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '5px', alignItems: 'center' }}>
                        <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL PEMBELIAN BERSIH
                          </Typography>
                          <Tooltip
                            title={`Data ini berasal dari perhitungan: Pembelian Barang - Diskon Pembelian - Return Pembelian - Potongan Pembelian Lain Lain.`}
                            placement='top'
                            classes={{ tooltip: classes.tooltip }}
                            arrow
                          >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </FlexBox>
                      </div>
                      <Typography variant='h6' className={classes.price}>
                        {incomeStatement.data.totalPurchaseCost < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.totalPurchaseCost)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.totalPurchaseCost} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CreateIncomeStatementContainer>
            </FlexBox>
          </FlexBox>
          {/* Total HPP */}
          <FlexBox container xs={12} className={classes.container} alignItems='flex-start'>
            <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
              <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                <Typography variant='h3' color={'initial'} className={clsx(classes.title)}>
                  HARGA POKOK PENJUALAN (HPP)
                </Typography>
              </FlexBox>
              <CreateIncomeStatementContainer isExpand={true}>
                <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between' onClick={() => setHpp(!hpp)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ExpandMoreRounded className={clsx(classes.icon, hpp && classes.iconRotate)} />
                        <Typography variant='h6' className={clsx(classes.priceNormal)}>
                          HARGA POKOK PENJUALAN (HPP)
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.costOfGoodsSold < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.costOfGoodsSold)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.costOfGoodsSold} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                    {hpp && (
                      <CreateIncomeStatementContainer isExpand={true}>
                        <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <div style={{ display: 'flex', marginLeft: '5px', alignItems: 'center' }}>
                                <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                                  <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                    PERSEDIAAN AWAL BARANG DAGANG
                                  </Typography>
                                  <Tooltip
                                    title={`Data ini berasal dari perhitungan: Total Stok Awal Tanggal Filter * Rata Rata Harga Pembelian Barang`}
                                    placement='top'
                                    classes={{ tooltip: classes.tooltip }}
                                    arrow
                                  >
                                    <IconButton size='small'>
                                      <InfoOutlinedIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                </FlexBox>
                              </div>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.beginningInventory < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.beginningInventory)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.beginningInventory}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                TOTAL PEMBELIAN BERSIH
                              </Typography>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.totalPurchaseCost < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.totalPurchaseCost)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.totalPurchaseCost}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>
                          <CreateIncomeStatementContainer isExpand={true}>
                            <FlexBox alignItems='center' container justify='space-between'>
                              <div style={{ display: 'flex', marginLeft: '5px', alignItems: 'center' }}>
                                <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                                  <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                    PERSEDIAAN AKHIR BARANG DAGANG
                                  </Typography>
                                  <Tooltip
                                    title={`Data ini berasal dari perhitungan: Total Stok Akhir Tanggal Filter * Rata Rata Harga Pembelian Barang`}
                                    placement='top'
                                    classes={{ tooltip: classes.tooltip }}
                                    arrow
                                  >
                                    <IconButton size='small'>
                                      <InfoOutlinedIcon fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                </FlexBox>
                              </div>
                              <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                {incomeStatement.data.endingInventory < 0 ? (
                                  <NumberFormat
                                    value={Math.abs(incomeStatement.data.endingInventory)}
                                    prefix={'(Rp '}
                                    suffix={')'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                ) : (
                                  <NumberFormat
                                    value={incomeStatement.data.endingInventory}
                                    prefix={'Rp '}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                )}
                              </Typography>
                            </FlexBox>
                          </CreateIncomeStatementContainer>
                        </FlexBox>
                      </CreateIncomeStatementContainer>
                    )}
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '5px', alignItems: 'center' }}>
                        <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL HPP
                          </Typography>
                          <Tooltip
                            title={`Data ini berasal dari perhitungan: Total Persediaan Awal + Total Pembelian - Total Persediaan Akhir`}
                            placement='top'
                            classes={{ tooltip: classes.tooltip }}
                            arrow
                          >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </FlexBox>
                      </div>
                      <Typography variant='h6' className={classes.price}>
                        {incomeStatement.data.costOfGoodsSold < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.costOfGoodsSold)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.costOfGoodsSold} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CreateIncomeStatementContainer>
            </FlexBox>
          </FlexBox>
          {/* keuntungan Kotor */}
          <FlexBox container xs={12} className={classes.container} alignItems='flex-start'>
            <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
              <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                <Typography variant='h6' color={'initial'} className={clsx(classes.title)}>
                  KEUNTUNGAN KOTOR
                </Typography>
              </FlexBox>
              <CreateIncomeStatementContainer isExpand={true}>
                <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                        <Typography variant='h6' className={clsx(classes.priceNormal)}>
                          TOTAL OMSET PENJUALAN
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.netRevenue < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.netRevenue)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.netRevenue} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                        <Typography variant='h6' className={clsx(classes.priceNormal)}>
                          TOTAL HPP
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.costOfGoodsSold < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.costOfGoodsSold)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.costOfGoodsSold} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '5px', alignItems: 'center' }}>
                        <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            TOTAL KEUNTUNGAN KOTOR
                          </Typography>
                          <Tooltip
                            title={`Data ini berasal dari perhitungan: Total Omset Penjualan - Total HPP`}
                            placement='top'
                            classes={{ tooltip: classes.tooltip }}
                            arrow
                          >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </FlexBox>
                      </div>
                      <Typography variant='h6' className={classes.price}>
                        {incomeStatement.data.grossProfit < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.grossProfit)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.grossProfit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CreateIncomeStatementContainer>
            </FlexBox>
          </FlexBox>
          {/* {Biaya operationa} */}
          <FlexBox container xs={12} className={classes.container} alignItems='flex-start'>
            <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
              <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                <Typography variant='h3' color={'initial'} className={clsx(classes.title)}>
                  BIAYA OPERATIONAL
                </Typography>
              </FlexBox>
              <CreateIncomeStatementContainer isExpand={true}>
                <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between' onClick={() => setCostOperational(!costOperational)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ExpandMoreRounded className={clsx(classes.icon, costOperational && classes.iconRotate)} />
                        <Typography variant='h6' className={clsx(classes.priceNormal)}>
                          BIAYA OPERATIONAL
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.cost < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.cost)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.cost} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                    {costOperational && (
                      <CreateIncomeStatementContainer isExpand={true}>
                        <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                          {operasionalResource.data.map(resource => (
                            <CreateIncomeStatementContainer isExpand={true}>
                              <Grid xs={12}>
                                <Divider />
                              </Grid>
                              <FlexBox alignItems='center' container justify='space-between'>
                                <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                  {resource.name} - {resource.number}
                                </Typography>
                                <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                  <NumberFormat value={resource.amount} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                                </Typography>
                              </FlexBox>
                            </CreateIncomeStatementContainer>
                          ))}
                        </FlexBox>
                      </CreateIncomeStatementContainer>
                    )}
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <Typography variant='h6' className={clsx(classes.price)}>
                        TOTAL BIAYA OPERATIONAL
                      </Typography>
                      <Typography variant='h6' className={classes.price}>
                        <NumberFormat value={incomeStatement.data.totalCost} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CreateIncomeStatementContainer>
            </FlexBox>
          </FlexBox>
          {/* Pendapatan lain  lain */}
          <FlexBox container xs={12} className={classes.container} alignItems='flex-start'>
            <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
              <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                <Typography variant='h6' color={'initial'} className={clsx(classes.title)}>
                  PENDAPATAN LAIN LAIN
                </Typography>
              </FlexBox>
              <CreateIncomeStatementContainer isExpand={true}>
                <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox
                      alignItems='center'
                      container
                      justify='space-between'
                      onClick={() => setOtherIncomeCategoryExpand(!otherIncomeCategoryExpand)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ExpandMoreRounded className={clsx(classes.icon, otherIncomeCategoryExpand && classes.iconRotate)} />
                        <Typography variant='h6' className={clsx(classes.priceNormal)}>
                          PENDAPATAN LAIN DILUAR USAHA
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.otherIncome < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.otherIncome)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.otherIncome} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>

                    {otherIncomeCategoryExpand && (
                      <CreateIncomeStatementContainer isExpand={true}>
                        <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                          {otherIncomeCategory.data.map(data => (
                            <CreateIncomeStatementContainer isExpand={true}>
                              <Grid xs={12}>
                                <Divider />
                              </Grid>
                              <FlexBox alignItems='center' container justify='space-between'>
                                <Typography variant='h6' className={clsx(classes.fontSizeNormal)} style={{ marginLeft: '20px' }}>
                                  {data.name} - {data.number}
                                </Typography>
                                <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                  <NumberFormat value={data.amount} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                                </Typography>
                              </FlexBox>
                            </CreateIncomeStatementContainer>
                          ))}
                        </FlexBox>
                      </CreateIncomeStatementContainer>
                    )}
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <Typography variant='h6' className={clsx(classes.price)}>
                        TOTAL PENDAPATAN LAIN LAIN
                      </Typography>
                      <Typography variant='h6' className={classes.price}>
                        {incomeStatement.data.otherIncome < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.otherIncome)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.otherIncome} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CreateIncomeStatementContainer>
            </FlexBox>
          </FlexBox>
          {/* Biaya lain lain */}
          <FlexBox container xs={12} className={classes.container} alignItems='flex-start'>
            <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
              <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                <Typography color={'initial'} className={clsx(classes.title)}>
                  BEBAN LAIN LAIN
                </Typography>
              </FlexBox>
              <CreateIncomeStatementContainer isExpand={true}>
                <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox
                      alignItems='center'
                      container
                      justify='space-between'
                      onClick={() => setOtherExpensesCategoryExpand(!otherExpensesCategoryExpand)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ExpandMoreRounded className={clsx(classes.icon, otherExpensesCategoryExpand && classes.iconRotate)} />
                        <Typography className={clsx(classes.priceNormal)}>BEBAN LAIN LAIN</Typography>
                      </div>
                      <Typography className={classes.priceNormal}>
                        {incomeStatement.data.otherExpenses < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.otherExpenses)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.otherExpenses} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>

                    {otherExpensesCategoryExpand && (
                      <CreateIncomeStatementContainer isExpand={true}>
                        <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                          {otherExpensesCategory.data.map(data => (
                            <CreateIncomeStatementContainer isExpand={true}>
                              <Grid xs={12}>
                                <Divider />
                              </Grid>
                              <FlexBox alignItems='center' container justify='space-between'>
                                <Typography className={clsx(classes.priceNormal)} style={{ marginLeft: '20px' }}>
                                  {data.name} - {data.number}
                                </Typography>
                                <Typography variant='h6' className={classes.fontSizeNormal} style={{ marginRight: '30px' }}>
                                  <NumberFormat value={data.amount} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                                </Typography>
                              </FlexBox>
                            </CreateIncomeStatementContainer>
                          ))}
                        </FlexBox>
                      </CreateIncomeStatementContainer>
                    )}
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <Typography variant='h6' className={clsx(classes.price)}>
                        TOTAL BEBAN LAIN LAIN
                      </Typography>
                      <Typography variant='h6' className={classes.price}>
                        {incomeStatement.data.otherExpenses < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.otherExpenses)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.otherExpenses} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CreateIncomeStatementContainer>
            </FlexBox>
          </FlexBox>
          {/* Laba bersih */}
          <FlexBox container xs={12} className={classes.container} alignItems='flex-start'>
            <FlexBox xs={12} container className={classes.itemWrapper} alignItems='center'>
              <FlexBox container alignItems='center' columnGap={1} className={classes.padding}>
                <Typography variant='h6' color={'initial'} className={clsx(classes.title)}>
                  LABA BERSIH / RUGI BERSIH
                </Typography>
              </FlexBox>
              <CreateIncomeStatementContainer isExpand={true}>
                <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                        <Typography variant='h6' className={clsx(classes.priceNormal)}>
                          TOTAL KEUNTUNGAN KOTOR
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.grossProfit < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.grossProfit)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.grossProfit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                        <Typography variant='h6' className={clsx(classes.priceNormal)}>
                          TOTAL PENDAPATAN LAIN LAIN
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.otherIncome < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.otherIncome)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.otherIncome} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                        <Typography variant='h6' className={clsx(classes.priceNormal)}>
                          TOTAL BIAYA OPERATIONAL
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.totalCost < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.totalCost)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.totalCost} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '35px', alignItems: 'center' }}>
                        <Typography variant='h6' className={clsx(classes.priceNormal)}>
                          TOTAL BEBAN LAIN LAIN
                        </Typography>
                      </div>
                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.otherExpenses < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.otherExpenses)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.otherExpenses} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>

                  <FlexBox xs={12} container className={clsx(classes.borderTop, classes.padding)}>
                    <FlexBox alignItems='center' container justify='space-between'>
                      <div style={{ display: 'flex', marginLeft: '5px', alignItems: 'center' }}>
                        <FlexBox alignItems='center' style={{ display: 'inline-flex' }}>
                          <Typography variant='h6' className={clsx(classes.price)}>
                            {incomeStatement.data.profitLoss < 0 ? 'TOTAL RUGI BERSIH' : '  TOTAL LABA BERSIH'}
                          </Typography>
                          <Tooltip
                            title={`Data ini berasal dari perhitungan: Total Keuntungan Kotor + Total Pendapatan Lain Lain - Total Biaya Operational - Total Beban Lain - Lain`}
                            placement='top'
                            classes={{ tooltip: classes.tooltip }}
                            arrow
                          >
                            <IconButton size='small'>
                              <InfoOutlinedIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </FlexBox>
                      </div>

                      <Typography variant='h6' className={classes.priceNormal}>
                        {incomeStatement.data.profitLoss < 0 ? (
                          <NumberFormat
                            value={Math.abs(incomeStatement.data.profitLoss)}
                            prefix={'(Rp '}
                            suffix={')'}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        ) : (
                          <NumberFormat value={incomeStatement.data.profitLoss} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                        )}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CreateIncomeStatementContainer>
            </FlexBox>
          </FlexBox>
        </>
      ) : (
        <Grid xs={12} container justify='center' alignItems='center' className={clsx(classes.minHight, classes.borderTop)}>
          <Typography variant='h6'>Tidak ada data.</Typography>
        </Grid>
      )}
    </>
  );
};

export default memo(CreateIncomeStatementContent);
