import { CircularProgress, Grid, InputAdornment, LinearProgress, ListItem, makeStyles, TextField, Typography } from '@material-ui/core';
import { CloseRounded, SearchRounded, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import FlexBox from 'components/FlexBox';
import { GRAY_3, GREY, WHITE } from 'constants/colors';
import React, { useState } from 'react';
import { dummyFilters, TData, TFilters } from 'pages/StatisticStockPage';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateRange, InfoRounded, RefreshOutlined, SortRounded } from '@material-ui/icons';
import { format } from 'date-fns';

const useStyles = makeStyles({
  productWrapper: {
    position: 'relative'
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  },
  absolute: {
    position: 'absolute',
    top: 290,
    width: '38vw',
    zIndex: 2
  },
  scrollY: {
    maxHeight: '50vh',
    overflowY: 'scroll'
  },
  searchIcon: {
    padding: '.1em',
    fontSize: '1.7rem',
    color: '#323232',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: GREY,
      borderRadius: '50%'
    }
  },
  colorGrey: {
    color: GRAY_3
  },
  wrapperInput: {
    backgroundColor: WHITE
  }
});
/* Defining the props that the component will receive. */
interface Props {
  filters: TFilters;
  productSuggests: { productName: string; id: number; wareHouse: string }[];
  selectedStartDate: Date;
  selectedEndDate: Date;
  loadProduct: boolean;
  isShowSuggest: boolean;
  setIsShowSuggest: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeFilters: <T>(key: keyof typeof dummyFilters, value: T) => void;
  handleClickSuggest: (productName: string, id: number) => void;
  handleClearKeyWord: () => void;
  handleSearchPress: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  setSelectedEndDate: React.Dispatch<React.SetStateAction<Date>>;
  setSelectedStartDate: React.Dispatch<React.SetStateAction<Date>>;
}

/* A function that returns a component. */
const StatisticStockFilters = ({
  filters,
  productSuggests,
  selectedStartDate,
  selectedEndDate,
  loadProduct,
  setIsShowSuggest,
  setSelectedEndDate,
  setSelectedStartDate,
  handleChangeFilters,
  handleClickSuggest,
  handleClearKeyWord,
  handleSearchPress
}: Props) => {
  const classes = useStyles();
  // const [selectedStartDate, setSelectedStartDate] = useState<string>(startDate);
  // const [selectedEndDate, setSelectedEndDate] = useState<string>(endDate);

  const handleEndDateChange = (selectedEndDate: Date | null) => {
    if (selectedEndDate) {
      setSelectedEndDate(selectedEndDate);
    }
  };

  const handleStartDateChange = (selectedStartDate: Date | null) => {
    if (selectedStartDate) {
      setSelectedStartDate(selectedStartDate);
    }
  };

  return (
    /* */

    <Grid container direction='row' spacing={1} justify='space-around'>
      <Grid item lg={6} sm={12} md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            margin='dense'
            id='periode'
            label='Dari'
            required
            format='dd-MM-yyyy'
            views={['year', 'month', 'date']}
            value={selectedStartDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'periode'
            }}
            InputProps={{
              className: classes.wrapperInput
            }}
            keyboardIcon={<ExpandMore fontSize='small' />}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item lg={6} sm={12} md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            margin='dense'
            id='periode'
            label='Sampai'
            required
            format='dd-MM-yyyy'
            views={['year', 'month', 'date']}
            value={selectedEndDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'periode'
            }}
            InputProps={{
              className: classes.wrapperInput
            }}
            keyboardIcon={<ExpandMore fontSize='small' />}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

/* Exporting the component. */
export default StatisticStockFilters;
