import { makeStyles, Theme, TableBody, TableRow, TableCell, Table, TableHead, Paper, TableContainer } from '@material-ui/core';
import React, { memo } from 'react';
import { TData, TListExpand } from 'pages/StatisticStockPage';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import { Skeleton } from '@material-ui/lab';

const size = {
  small: 0.1,
  medium: 0.15,
  large: 0.25,
  extralarge: 0.35
} as const;

type TTableProperty = {
  key: keyof StockStatistic;
  title: string;
  size: keyof typeof size;
  align: 'left' | 'center' | 'right';
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
    border: '1px solid #ddd', // Border untuk tabel
    borderCollapse: 'collapse',
    borderRadius: 5
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: theme.spacing(1)
  },

  dateCell: {
    width: '120px',
    minWidth: '120px'
  },

  narrowCell: {
    width: '150px',
    minWidth: '150px'
  },
  wideCell: {
    width: '220px',
    minWidth: '220px'
  }
}));

/* Defining the props that the component will receive. */
interface Props {
  cashHistory: TData<CashHistory[]>;
}

/* A function component. */

const StatisticStockContent = ({ cashHistory }: Props) => {
  const classes = useStyles();

  console.log('cashHistory -->', cashHistory.data);

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.dateCell}`}>Tanggal</TableCell>
              <TableCell className={`${classes.wideCell}`}>
                Aktivitas
                <br />
                No. Transaksi
              </TableCell>
              <TableCell align='right'>Debit</TableCell>
              <TableCell align='right'>Credit</TableCell>
              <TableCell align='right'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cashHistory.isLoading ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Skeleton variant='text' animation='wave' height={60} />
                </TableCell>
              </TableRow>
            ) : (
              cashHistory.data.map(row => (
                <TableRow key={row.createdAt}>
                  <TableCell align='left' className={`${classes.dateCell}`}>
                    {format(new Date(row.createdAt), 'dd MMM yyyy')}
                  </TableCell>
                  <TableCell align='left' className={`${classes.wideCell}`}>
                    <strong>
                      {' '}
                      {row.type ?? '-'} {row.name ? ` ${row.name}` : ' '}
                    </strong>{' '}
                    <br />
                    {row.number ?? ' '}
                  </TableCell>

                  <TableCell align='right' className={`${classes.narrowCell}`}>
                    {row.debit < 0 ? (
                      <NumberFormat value={Math.abs(row.debit)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
                    ) : (
                      <NumberFormat value={row.debit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                    )}
                  </TableCell>
                  <TableCell align='right' className={`${classes.narrowCell}`}>
                    {row.credit < 0 ? (
                      <NumberFormat value={Math.abs(row.credit)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
                    ) : (
                      <NumberFormat value={row.credit} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                    )}
                  </TableCell>
                  <TableCell align='right' className={`${classes.narrowCell}`}>
                    {row.saldoKas < 0 ? (
                      <NumberFormat value={Math.abs(row.saldoKas)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
                    ) : (
                      <NumberFormat value={row.saldoKas} prefix={'Rp '} thousandSeparator={true} displayType='text' />
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(StatisticStockContent);
