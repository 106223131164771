import { Button, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { Create, Delete, DeleteOutline, EditOutlined, Visibility, AttachFile, VisibilityOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';

import { BLACK, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import React from 'react';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  cellStart: {
    width: '3%'
  },
  cellMiddle: {
    width: '14%'
  },
  cellMiddleDesc: {
    width: '15%'
  },
  cellEnd: {
    width: '15%'
  },
  descriptionContainer: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid #E0E0E0'
  },
  description: {
    color: BLACK
  },
  gap: {
    marginBottom: theme.spacing(2)
  }
}));
interface Props {
  closeBook: CloseBookModel;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail') => void;
}
const CloseBookBody = ({ closeBook, handleAction }: Props) => {
  const classes = useStyles();
  return (
    <>
      <TableRowCustom key={closeBook.id}>
        <TableCellStart className={clsx(classes.cellMiddle)} onClick={() => handleAction(true, closeBook.id, 'detail')}>
          {format(new Date(closeBook.startDate), 'dd MMM yyyy')} s/d {format(new Date(closeBook.endDate), 'dd MMM yyyy')}
        </TableCellStart>
        {/* <TableCellMiddle className={classes.cellMiddle} align='center' onClick={() => handleAction(true, closeBook.id, 'detail')}>
          {closeBook.netRevenue < 0 ? (
            <NumberFormat value={Math.abs(closeBook.netRevenue)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
          ) : (
            <NumberFormat value={closeBook.netRevenue} prefix={'Rp '} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle> */}
        {/* <TableCellMiddle className={classes.cellMiddle} align='center' onClick={() => handleAction(true, closeBook.id, 'detail')}>
          {closeBook.cost < 0 ? (
            <NumberFormat value={Math.abs(closeBook.cost)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
          ) : (
            <NumberFormat value={closeBook.cost} prefix={'Rp '} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle> */}

        <TableCellMiddle className={classes.cellMiddle} align='center' onClick={() => handleAction(true, closeBook.id, 'detail')}>
          {closeBook.total < 0 ? (
            <NumberFormat value={Math.abs(closeBook.total)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
          ) : (
            <NumberFormat value={closeBook.total} prefix={'Rp '} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>
        <TableCellMiddle className={classes.cellMiddle} align='center' onClick={() => handleAction(true, closeBook.id, 'detail')}>
          {closeBook.totalAsetTetap < 0 ? (
            <NumberFormat value={Math.abs(closeBook.totalAsetTetap)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
          ) : (
            <NumberFormat value={closeBook.totalAsetTetap} prefix={'Rp '} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>
        <TableCellMiddle className={classes.cellMiddle} align='center' onClick={() => handleAction(true, closeBook.id, 'detail')}>
          {closeBook.TotalCurrentAssets < 0 ? (
            <NumberFormat value={Math.abs(closeBook.TotalCurrentAssets)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
          ) : (
            <NumberFormat value={closeBook.TotalCurrentAssets} prefix={'Rp '} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>
        <TableCellMiddle className={classes.cellMiddle} align='center' onClick={() => handleAction(true, closeBook.id, 'detail')}>
          {closeBook.totalLiabilities < 0 ? (
            <NumberFormat value={Math.abs(closeBook.totalLiabilities)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
          ) : (
            <NumberFormat value={closeBook.totalLiabilities} prefix={'Rp '} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>
        <TableCellMiddle className={classes.cellMiddle} align='center' onClick={() => handleAction(true, closeBook.id, 'detail')}>
          {closeBook.totalEkuitas < 0 ? (
            <NumberFormat value={Math.abs(closeBook.totalEkuitas)} prefix={'(Rp '} suffix={')'} thousandSeparator={true} displayType='text' />
          ) : (
            <NumberFormat value={closeBook.totalEkuitas} prefix={'Rp '} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>
        <TableCellEnd className={classes.cellEnd} align='right'>
          <>
            <Tooltip title='Hapus Biaya'>
              <IconButton size='small' onClick={() => handleAction(true, closeBook.id, 'delete')}>
                <DeleteOutline fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        </TableCellEnd>
      </TableRowCustom>
    </>
  );
};

export default CloseBookBody;
