import React from 'react';
import { TypeJournalTransactions } from 'pages/TransactionJurnalPage';
import {
  Grid,
  makeStyles,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components';
import { Skeleton } from '@material-ui/lab';
import TransactionBody from './TransactionBody';
import { TData, TypeCashBalanceModel } from '..';

interface Props {
  cashBalance: TypeCashBalanceModel;
  balanceSheet: TData<BalanceSheet>;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail') => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellTable: {
    width: '20%'
  },
  cellTableSecond: {
    width: '5%'
  },
  container: {
    minHeight: '30vh',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)'
  },
  cellStartId: {
    width: '5%'
  },
  cellTable3: {
    width: '13%'
  }
}));
const TransactionTable = ({ balanceSheet, cashBalance, handleAction }: Props) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            headers={[
              { id: 'akun', label: 'Akun Kas', align: 'left' },
              { id: 'total', label: 'Kas', align: 'right' }
            ]}
          />
        </TableHead>

        <TableBody>
          {cashBalance.isLoading ? (
            <TableRow>
              <TableCell colSpan={8}>
                <Grid xs={12} container>
                  {[...new Array(5)].map((val, key) => (
                    <Grid xs={12}>
                      <Skeleton variant='text' animation='wave' height={60} key={key} />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            </TableRow>
          ) : balanceSheet.data != null ? (
            balanceSheet.data.bank
              .filter((dataKas: any) => dataKas.number !== 100001 && dataKas.number !== 100002) // Perbaikan logika filter
              .map((dataKas: any) => (
                <TransactionBody key={dataKas.id} balanceSheet={dataKas} cashBalance={cashBalance} handleAction={handleAction} />
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} align='center'>
                <Grid xs={12} className={classes.container} container alignItems='center' justify='center'>
                  <Typography variant='h6'>Data Tidak Tersedia.</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default TransactionTable;
