import React, { FC, useContext } from 'react';

import { List, makeStyles } from '@material-ui/core';
import { mdiCubeOutline, mdiLayersTripleOutline, mdiAccountMultipleOutline, mdiLogout, mdiHomeOutline } from '@mdi/js';
import { WHITE } from 'constants/colors';
import DrawerItem from './DrawerItem';
import SettingsIcon from '@material-ui/icons/Settings';
import InvoiceIcon from '@material-ui/icons/DescriptionOutlined';
import PieChartIcon from '@material-ui/icons/PieChartOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import { hasAccess } from 'selectors';
import TypeUser from 'typings/enum/TypeUser';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import { modules } from 'constants/modules';
import { HomeOutlined, LocalShippingOutlined } from '@material-ui/icons';

interface Props {
  currentUserData: CurrentUser | undefined;
  handleDrawerClose(): void;
  useNotification?: boolean;
  totalNotification?: number;
  totalRequestEdit?: number;
  totalNewEdit?: number;
  totalNotifSJ?: number;
}

const useStyles = makeStyles({
  textIcon: {
    color: WHITE,
    fontSize: 50,
    fontWeight: 'bold'
  }
});

const DrawerList: FC<Props> = props => {
  const classes = useStyles();
  const { handleDrawerClose, currentUserData, totalNotification, totalRequestEdit, totalNewEdit, totalNotifSJ } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });
  const isHR = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.HR]
  });

  const isAdminReturn = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN_RETURN]
  });

  const isAdmin03 = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN04]
  });

  const isBuyer = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.BUYER]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  const isFinance = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN03]
  });

  const isPackingPlus = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN02PLUS]
  });
  const isChecker = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN01]
  });

  const isSupervisor = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERVISOR]
  });
  const isSales = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SALES]
  });

  const isAccounting = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ACCOUNTING]
  });

  const isDriver = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.DRIVER]
  });

  const products = [
    { name: 'Katalog', path: '/produk' },
    { name: 'Kategori', path: '/kategori' }
  ];

  const stocks = [
    { name: 'Semua Stok', path: '/semuastok' },
    { name: 'Stok Masuk', path: '/stok' },
    { name: 'Stok Keluar', path: '/stokKeluar' },
    { name: 'Mutasi Stok', path: '/mutasi' },
    { name: 'Pengecekan Stok', path: '/cek-stok' },
    { name: 'Pergerakan Stok', path: '/pergerakan-stok' },
    { name: 'Statistik Stok', path: '/statistik-stok' }
  ];

  const orders = [
    { name: 'Penjualan', path: '/penjualan' },
    { name: 'Pembelian', path: '/purchase' }
  ];

  const returns = [{ name: 'Return Penjualan', path: '/return-penjualan' }];
  const payments = [
    { name: 'Penjualan', path: '/pembayaran-penjualan' },
    { name: 'Pembelian', path: '/pembayaran-pembelian' }
  ];

  const accounting = [
    { name: 'Akunting', path: '/bagan-akun' },
    { name: 'Biaya', path: '/biaya' },
    { name: 'Modal Awal', path: '/modal-awal' },
    { name: 'Laba Rugi', path: '/laba-rugi' },
    { name: 'Neraca', path: '/neraca' },
    { name: 'Tutup Buku', path: '/tutup-buku' },
    { name: 'Komisi', path: '/komisi' },
    { name: 'Keuangan', path: '/keuangan' }
  ];

  const invoices = [{ name: 'Penjualan', path: '/invoice' }];

  if (isSuperAdmin) {
    invoices.push({ name: 'Pembelian', path: '/invoice-pembelian' }, { name: 'Tagihan Return', path: '/tagihan-return' });
    returns.unshift({ name: 'Barang Rusak', path: '/return-barang-rusak' });
    returns.push({ name: 'Return Pembelian', path: '/return-pembelian' });
  }
  if (isAdmin) {
    invoices.push({ name: 'Tagihan Return', path: '/tagihan-return' });
    returns.unshift({ name: 'Barang Rusak', path: '/return-barang-rusak' });
    returns.push({ name: 'Return Pembelian', path: '/return-pembelian' });
  }

  if (isAccounting) {
    invoices.push({ name: 'Pembelian', path: '/invoice-pembelian' });
  }

  if (isFinance) {
    invoices.push({ name: 'Tagihan Return', path: '/tagihan-return' });
    returns.push({ name: 'Return Pembelian', path: '/return-pembelian' });
  }

  if (isBuyer) {
    invoices.push({ name: 'Pembelian', path: '/invoice-pembelian' });
    returns.length = 0;
    returns.push({ name: 'Return Pembelian', path: '/return-pembelian' });
  }

  if (isAdminReturn) {
    returns.unshift({ name: 'Barang Rusak', path: '/return-barang-rusak' });
    returns.push({ name: 'Return Pembelian', path: '/return-pembelian' });
  }

  return (
    <List className={classes.textIcon}>
      {(isAdmin || isSuperAdmin || isHR) && (
        <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiHomeOutline} path='/dashboard' label='Dashboard' child={false} />
      )}
      {hasAccess(currentUserData, modules.PARTNER) && (
        <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiAccountMultipleOutline} path='/mitra' label='Mitra' child={false} />
      )}

      {!isChecker && !isAdmin03 && (hasAccess(currentUserData, modules.PRODUCT) || hasAccess(currentUserData, modules.CATEGORIES)) ? (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          iconMdi={mdiCubeOutline}
          path='#'
          label='Produk'
          child={true}
          childData={products.filter(product => {
            if (product.name === 'Katalog') {
              return hasAccess(currentUserData, modules.PRODUCT);
            }

            if (product.name === 'Kategori' && !!currentUserData && currentUserData.type !== TypeUser.SALES) {
              return hasAccess(currentUserData, modules.CATEGORIES);
            }
          })}
        />
      ) : (
        ''
      )}

      {!isPackingPlus && !isAccounting && (hasAccess(currentUserData, modules.STOCK) || hasAccess(currentUserData, modules.PURCHASE_ORDER)) ? (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          iconMdi={mdiLayersTripleOutline}
          path='#'
          label='Data Stok'
          child={true}
          childData={stocks.filter(stock => {
            if (stock.name === 'Mutasi Stok') {
              return hasAccess(currentUserData, modules.PURCHASE_ORDER);
            }
            if (isFinance || isChecker) {
              return stock.name.includes('Semua Stok');
            }

            return hasAccess(currentUserData, modules.STOCK);
          })}
        />
      ) : (
        ''
      )}

      {hasAccess(currentUserData, modules.PURCHASE_ORDER) ||
      hasAccess(currentUserData, modules.SALES_ORDER) ||
      hasAccess(currentUserData, modules.HOLDING_RETURN) ? (
        isDriver ? (
          <DrawerItem handleDrawerClose={handleDrawerClose} Icon={ShoppingCartIcon} path='/return' label='Return' child={false} />
        ) : (
          <DrawerItem
            handleDrawerClose={handleDrawerClose}
            Icon={ShoppingCartIcon}
            path='#'
            label='Order'
            child={true}
            useNotification={isSuperAdmin || isAdmin ? false : true}
            totalNotification={totalNotification}
            totalRequestEdit={totalRequestEdit}
            totalNewEdit={totalNewEdit}
            childData={orders.filter(order => {
              switch (order.name) {
                case 'Penjualan':
                  return hasAccess(currentUserData, modules.SALES_ORDER);
                case 'Pembelian':
                  return !isAccounting && hasAccess(currentUserData, modules.PURCHASE_ORDER);
              }
            })}
          />
        )
      ) : (
        ''
      )}

      {(isSuperAdmin || isFinance || isSupervisor || isAdmin || isAdmin03 || isBuyer || isAccounting) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          Icon={InvoiceIcon}
          path='#'
          label='Invoice'
          useNotification={isSuperAdmin ? false : isAdmin ? false : true}
          totalNotification={isSuperAdmin ? totalRequestEdit : isAdmin ? totalNewEdit : 0}
          totalRequestEdit={totalRequestEdit}
          totalNewEdit={totalNewEdit}
          child={true}
          childData={invoices.filter(invoice => {
            switch (invoice.name) {
              case 'Penjualan':
                return hasAccess(currentUserData, modules.INVOICE);

              case 'Pembelian':
                return !isAdmin && hasAccess(currentUserData, modules.PURCHASE_INVOICE);
              case 'Tagihan Return':
                return true;
            }
          })}
        />
      )}
      {hasAccess(currentUserData, modules.DELIVERY) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          totalNotification={isAdmin || isSuperAdmin ? totalNotifSJ : 0}
          Icon={LocalShippingOutlined}
          path='/surat-jalan'
          label='Surat Jalan'
          child={false}
        />
      )}
      {hasAccess(currentUserData, modules.INVOICE_RETURN) || hasAccess(currentUserData, modules.HOLDING_RETURN) ? (
        isDriver ? (
          <DrawerItem handleDrawerClose={handleDrawerClose} Icon={ShoppingCartIcon} path='/return-penjualan' label='Return' child={false} />
        ) : (
          <DrawerItem
            handleDrawerClose={handleDrawerClose}
            Icon={ShoppingCartIcon}
            path='#'
            label='Return'
            child={true}
            childData={returns.filter(order => {
              switch (order.name) {
                case 'Barang Rusak':
                  return hasAccess(currentUserData, modules.SALES_ORDER) || hasAccess(currentUserData, modules.INVOICE_RETURN);
                case 'Return Penjualan':
                  return hasAccess(currentUserData, modules.INVOICE_RETURN);
                case 'Return Pembelian':
                  return hasAccess(currentUserData, modules.INVOICE_RETURN);
              }
            })}
          />
        )
      ) : (
        ''
      )}

      {!isAdmin && hasAccess(currentUserData, modules.INVOICE_PAYMENT) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          Icon={PaymentIcon}
          path='#'
          label='Pembayaran'
          child={true}
          childData={payments.filter(payment => {
            switch (payment.name) {
              case 'Penjualan':
                return hasAccess(currentUserData, modules.SALES_ORDER) || hasAccess(currentUserData, modules.INVOICE_RETURN);
              case 'Pembelian':
                return !isSales && !isSupervisor && hasAccess(currentUserData, modules.INVOICE_RETURN);
            }
          })}
        />

        // <DrawerItem handleDrawerClose={handleDrawerClose} Icon={PaymentIcon} path='/pembayaran' label='Pembayaran' child={false} />
      )}
      {!isAdmin && hasAccess(currentUserData, modules.FINANCE) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          Icon={PieChartIcon}
          path='#'
          label='Keuangan'
          child={true}
          childData={accounting.filter(accounting => {
            switch (accounting.name) {
              case 'Akunting':
                return hasAccess(currentUserData, modules.INCOME_STATEMENT);
              case 'Biaya':
                return hasAccess(currentUserData, modules.SALES_ORDER) || hasAccess(currentUserData, modules.INVOICE_RETURN);
              case 'Modal Awal':
                return hasAccess(currentUserData, modules.CASH_BALANCE);
              case 'Laba Rugi':
                return hasAccess(currentUserData, modules.INCOME_STATEMENT);
              case 'Neraca':
                return hasAccess(currentUserData, modules.BALANCE_SHEET);
              case 'Tutup Buku':
                return hasAccess(currentUserData, modules.CLOSE_BOOK);
              case 'Komisi':
                return !isAdmin && hasAccess(currentUserData, modules.COMMISSION);
              case 'Keuangan':
                return isSuperAdmin && hasAccess(currentUserData, modules.FINANCE);
            }
          })}
        />

        // <DrawerItem handleDrawerClose={handleDrawerClose} Icon={PaymentIcon} path='/pembayaran' label='Pembayaran' child={false} />
      )}
      {/* {!isAdmin && hasAccess(currentUserData, modules.COMMISSION) && (
        <DrawerItem handleDrawerClose={handleDrawerClose} Icon={PieChartIcon} path='/komisi' label='Komisi' child={false} />
      )}

      {isSuperAdmin && hasAccess(currentUserData, modules.FINANCE) && (
        <DrawerItem handleDrawerClose={handleDrawerClose} Icon={PieChartIcon} path='/keuangan' label='Keuangan' child={false} />
      )} */}

      {(!isAdmin && hasAccess(currentUserData, modules.USERS)) ||
      (!isAdmin && hasAccess(currentUserData, modules.COMPANY)) ||
      (!isAdmin && hasAccess(currentUserData, modules.WAREHOUSE)) ||
      (!isAdmin && hasAccess(currentUserData, modules.ZONE))
        ? !!currentUserData &&
          currentUserData.type !== TypeUser.SALES &&
          currentUserData.type !== TypeUser.PICKER &&
          currentUserData.type !== TypeUser.ADMIN01 &&
          currentUserData.type !== TypeUser.BUYER &&
          currentUserData.type !== TypeUser.ADMIN02 &&
          currentUserData.type !== TypeUser.ADMIN03 &&
          currentUserData.type !== TypeUser.ADMIN02PLUS &&
          !isAccounting &&
          !isHR &&
          !isAdmin03 && <DrawerItem handleDrawerClose={handleDrawerClose} Icon={SettingsIcon} path='/pengaturan' label='Pengaturan' child={false} />
        : ''}
      {!isAdmin && hasAccess(currentUserData, modules.APPLOG) && (
        <DrawerItem handleDrawerClose={handleDrawerClose} Icon={ExitToAppIcon} path='/appLog' label='App Log' child={false}></DrawerItem>
      )}
      <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiLogout} path='logout' label='Keluar' child={false} />
    </List>
  );
};

export default DrawerList;
