import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { Container, Grid, makeStyles, Typography, Theme } from '@material-ui/core';
import axios from 'axios';

import { Breadcrumb, PaperCustom } from 'components';
import { format } from 'date-fns';
import FlexBox from 'components/FlexBox';
import { CASH_TRANSACTIONS } from 'constants/url';

import { dummyMetaData } from 'utils/dummy';
import { StatisticStockFilters } from './components';

import { GREY } from 'constants/colors';

import { useParams } from 'react-router-dom';
const StatisticStockContent = lazy(() => import('./components/StatisticStockContent'));
export type TListExpand = {
  id: number | string;
  action: 'products' | 'months';
};

export type TFilters = {
  keyWord: string;
  productName: string;
  categoryId: number;
  zoneId: number;
  warehouseId: number;
  id: number;
  startDate: string;
  endDate: string;
};

export type TData<T> = {
  isLoading: boolean;
  data: T;
};

/* Defining a type called TFilters and then creating a constant called dummyFilters that is of type
TFilters. */
const currentYear = new Date().getFullYear();
const startOfYear = new Date(currentYear, 0, 1);
const today = new Date();
export const dummyFilters: TFilters = {
  productName: '',
  keyWord: '',
  categoryId: 0,
  zoneId: 0,
  warehouseId: 0,
  id: 0,
  startDate: format(startOfYear, 'yyyy-MM'),
  endDate: format(today, 'yyyy-MM')
};

const dummyData = {
  isLoading: true,
  data: []
};

const dummySummary: stockMovementSummary = {
  totalStockFirstStart: 0,
  totalStockDamageStart: 0,
  totalStockSalesStart: 0,
  totalStockEnd: 0,
  totalStockDamageEnd: 0,
  totalStockSalesEnd: 0
};

const useStyles = makeStyles((theme: Theme) => ({
  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  minHight: {
    minHeight: '25em'
  },
  logo: {
    width: '400px',
    height: '337px',
    marginTop: '2em'
  }
}));

type RouteParams = {
  id: string;
};

const StatisticStockPage = () => {
  const { id } = useParams<RouteParams>();
  const urlParams = new URLSearchParams(window.location.search);
  const startDate = urlParams.get('startDate');
  const endDate = urlParams.get('endDate');

  const classes = useStyles();
  const [filters, setFilters] = useState<TFilters>(dummyFilters);
  const [cashHistory, setCashHistory] = useState<TData<CashHistory[]>>(dummyData);
  const [productSuggests, setProductSuggests] = useState<{ productName: string; id: number; wareHouse: string }[]>([]);
  const [productMeta, setProductMeta] = useState<MetaData>(dummyMetaData);
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(startOfYear);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

  const [loadProduct, setLoadProduct] = useState<boolean>(false);
  const [isShowSuggest, setIsShowSuggest] = React.useState(false);

  const handleChangeFilters = <T,>(key: keyof typeof dummyFilters, value: T) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const handleClickSuggest = (productName: string, id: number) => {
    handleChangeFilters('id', id);
    handleChangeFilters('keyWord', productName);
    handleChangeFilters('productName', productName);
    handleChangeFilters('categoryId', 0);
    setProductMeta(prev => ({ ...prev, current_page: 1 }));
    setProductSuggests([]);
    setIsShowSuggest(false);
  };

  const handleSearchPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleChangeFilters('productName', filters.keyWord);
      setProductMeta(prev => ({ ...prev, current_page: 1 }));
      setProductSuggests([]);
    }
  };

  const handleClearKeyWord = () => {
    handleChangeFilters('keyWord', '');
    handleChangeFilters('id', '');
    handleChangeFilters('productName', '');
    setProductSuggests([]);
  };

  const formatDateToLocalISO = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Bulan dimulai dari 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const fetchCashHistory = async () => {
    const formattedStartDate = formatDateToLocalISO(selectedStartDate);
    const formattedEndDate = formatDateToLocalISO(selectedEndDate);
    setCashHistory(prev => ({
      ...prev,
      isLoading: true
    }));
    const params = new URLSearchParams();
    params.append('financialAccount', id);

    params.append('startDate', formattedStartDate);
    params.append('endDate', formattedEndDate);

    try {
      console.log('params-->', selectedStartDate.toISOString().split('T')[0]);
      const { data } = await axios.get(`${CASH_TRANSACTIONS}?${params}`, {
        cancelToken
      });
      setCashHistory(prev => ({
        ...prev,
        isLoading: false,
        data: data
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadProduct(false);
    }
  };

  const handleRefresh = () => {
    setFilters(dummyFilters);
    setProductMeta(prev => ({ ...prev, current_page: 1 }));
  };

  useEffect(() => {
    fetchCashHistory();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <Container>
      <Grid container direction='row' style={{ marginTop: '1em' }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant='h1'>Kas</Typography>
        </Grid>
      </Grid>
      <PaperCustom>
        <FlexBox container rowGap={1}>
          <StatisticStockFilters
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            productSuggests={productSuggests}
            loadProduct={loadProduct}
            isShowSuggest={isShowSuggest}
            setIsShowSuggest={setIsShowSuggest}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedStartDate={setSelectedStartDate}
            setSelectedEndDate={setSelectedEndDate}
            handleClickSuggest={handleClickSuggest}
            handleClearKeyWord={handleClearKeyWord}
            handleSearchPress={handleSearchPress}
          />

          <>
            <Suspense
              fallback={
                <Grid xs={12} container justify='center' alignItems='center'>
                  <Typography variant='h6'>Sedang Membuka...</Typography>
                </Grid>
              }
            >
              <StatisticStockContent cashHistory={cashHistory} />
            </Suspense>
          </>
        </FlexBox>
      </PaperCustom>
    </Container>
  );
};
export default StatisticStockPage;
