import { Grid, makeStyles, Container, Typography, Button, Link, Breadcrumbs, Tabs, Tab } from '@material-ui/core';
import { Add, AddBox, Search, Today } from '@material-ui/icons';
import { Pagination, Skeleton } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import DateRangeFilter from 'components/DateRangeFilter';
import { ACCOUNT_CATEGORY_BASE_URL, CLOSE_BOOK_BASE_URL, JOURNAL_TRANSACTIONS_BASE_URL, OPERATIONAL_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Page, PaperCustom, StandardConfirmationDialog } from 'components';
import CloseBookTable from 'pages/CloseBookPage/components/CloseBookTable';
import { dummyMetaData } from 'utils/dummy';
import useRouter from 'hooks/useRouter';
import CloseBookDelete from './components/CloseBookDelete';
import FlexBox from 'components/FlexBox';
import Loading from 'components/Loading';
import { GREY } from 'constants/colors';
import clsx from 'clsx';

const useStyles = makeStyles({
  active: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px'
  },
  breadCrumb: {
    marginTop: '3px'
  },
  tabPanel: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  minHight: {
    minHeight: '25em'
  },
  tableWrapper: {
    backgroundColor: '#F7F9FC'
  },
  padding: {
    padding: '1em 1em .8em'
  },
  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  fontSizeNormal: {
    fontSize: '.9rem',
    fontWeight: 'normal'
  }
});
const dummyAccountCategory: AccountCategoryModel = {
  id: 0,
  name: '',
  category: '',
  number: 0,
  subAccount: 0,
  isEditable: true,
  isActive: true,
  subItemAccount: []
};
export type TypeJournalTransactions = {
  isLoading: boolean;
  data: transactionJurnal[];
};

export type TypeCloseBook = {
  isLoading: boolean;
  data: CloseBookModel[];
};

const CloseBookPage = () => {
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const { history } = useRouter();
  const classes = useStyles();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');

  const [queryString, setQueryString] = useState<string>();
  const [message, setMessage] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [transaksi, setTransaksi] = useState<string>('');
  const [akun, setAkun] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [journalTransactions, setJournalTransactions] = useState<TypeJournalTransactions>({
    isLoading: false,
    data: []
  });

  const [closeBook, setCloseBook] = useState<TypeCloseBook>({
    isLoading: false,
    data: []
  });

  const [isAction, setIsAction] = useState<{
    isOpen: boolean;
    isLoading: boolean;
    id: number;
    action: 'delete' | 'update' | 'detail';
    type: string;
  }>({
    isOpen: false,
    isLoading: false,
    id: 0,
    action: 'delete',
    type: ''
  });

  const fetchData = useCallback(async () => {
    setCloseBook(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }
      if (transaksi) {
        params.append('transaksi', transaksi);
      }
      if (akun) {
        params.append('akun', akun);
      }

      params.append('page', currentPage.toString());
      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));

      params.append('orderBy', 'startDate');
      params.append('ordered', order);

      return params;
    };

    try {
      const { data } = await axios.get(`${CLOSE_BOOK_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });

      setCloseBook(prev => ({ ...prev, isLoading: false, data: data.data }));
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [queryString, order, orderBy, startDate, endDate, currentPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData, queryString]);

  const onClickHandler = (path: string): React.MouseEventHandler => event => {
    event.preventDefault();
    history.push(path);
  };

  const handleOpenForm = () => {
    history.push('/tutup-buku/tambah');
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleDelete = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.delete(`${CLOSE_BOOK_BASE_URL}/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
      handleSnackBar(true, 'success', 'Tutup buku berhasil dihapus.');
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail' = 'delete') => {
    setIsAction(prev => ({ ...prev, isOpen, id, action }));

    // Filter closeBook berdasarkan ID
    const selectedBook = closeBook.data.find((book: CloseBookModel) => book.id === id);

    // Pastikan data ditemukan sebelum mengakses startDate dan endDate
    const startDate = selectedBook ? selectedBook.startDate : '';
    const endDate = selectedBook ? selectedBook.endDate : '';

    // Fungsi untuk membangun query params
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (startDate) params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      if (endDate) params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      return params;
    };

    if (action === 'detail' && selectedBook) {
      history.push(`/tutup-buku-detail?${getQueryParams()}`);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Page title='Tutup Buku'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Tutup buku </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumbs key={1} aria-label='breadcrumb' className={classes.breadCrumb}>
              <Link key={99} color='inherit' href='/' onClick={onClickHandler('/')}>
                Beranda
              </Link>
              <Link color='inherit' href='/' onClick={onClickHandler('/pengaturan')}>
                Tutup Buku
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container direction='row' justify='flex-end'>
                <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
                  <Grid item>
                    <Button disabled={isLoadingData} onClick={handleOpenForm}>
                      <AddBox fontSize='small' /> &nbsp; Tambah Tutup Buku
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justify='center' item xl={12} lg={12} md={12} sm={12} xs={12}>
              {closeBook.isLoading ? (
                <FlexBox xs={12} container rowGap={1} className={clsx(classes.padding, classes.borderTop)}>
                  <Grid
                    xs={12}
                    className={clsx(classes.tableWrapper, classes.minHight)}
                    container
                    alignItems='center'
                    justify='center'
                    direction='column'
                  >
                    <Loading />
                    <Typography variant='h6' className={classes.fontSizeNormal}>
                      Sedang Membuka...
                    </Typography>
                  </Grid>
                </FlexBox>
              ) : (
                // Tampilkan CloseBookTable jika sudah tidak loading
                <CloseBookTable
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  setTransaksi={setTransaksi}
                  setAkun={setAkun}
                  akun={akun}
                  closeBook={closeBook}
                  transaksi={transaksi}
                  journalTransactions={journalTransactions}
                  handleAction={handleAction}
                />
              )}

              {closeBook.data.length > 0 && (
                <Grid xs={12} container>
                  <Pagination
                    count={meta.last_page}
                    onChange={(event, page) => setCurrentPage(page)}
                    page={meta.current_page}
                    boundaryCount={2}
                    variant='outlined'
                    shape='rounded'
                  />
                </Grid>
              )}

              <StandardConfirmationDialog
                variant={snackbarVariant}
                titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
                message={message}
                open={openSnackbar}
                handleClose={handleCloseSnackbar}
                onConfirm={handleCloseSnackbar}
                noCancelButton={true}
              />

              <CloseBookDelete
                open={isAction.isOpen && isAction.action.includes('delete')}
                handleClose={() => handleAction(false, 0, 'delete')}
                handleSubmit={handleDelete}
                isLoading={isAction.isLoading}
              />
            </Grid>
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CloseBookPage;
