import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import DateRangeFilter from 'components/DateRangeFilter';
import { ACCOUNT_CATEGORY_BASE_URL, BALANCE_SHEET_BASE_URL, COST_BALANCE_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import TransactionTable from './components/TransactionTable';
import { GREEN, WHITE } from 'constants/colors';
import { CashBalance } from 'typings/CashBalanceModel';
import useRouter from 'hooks/useRouter';

export type TypeJournalTransactions = {
  isLoading: boolean;
  data: transactionJurnal[];
};

export type TypeCashBalanceModel = {
  isLoading: boolean;
  data: AccountCategoryModel[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '2em',
    marginTop: '2em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));
const dummyBalanceSheet: BalanceSheet = {
  cash: 0,
  briBank: 0,
  mandiriBank: 0,
  bcaBank: 0,
  giro: 0,
  totalCash: 0,
  accountsReceivable: 0,
  purchaseInvoiceReturn: 0,
  InvoiceReturnBill: 0,
  purchaseAdvance: 0,
  endingInventory: 0,
  totalAccountsReceivable: 0,
  TotalCurrentAssets: 0,
  vehicles: 0,
  buildings: 0,
  equipmentAndMachinery: 0,
  totalAsetTetap: 0,
  totalActiva: 0,
  accountsPayableToSuppliers: 0,
  salesPayment: 0,
  totalShortLiabilities: 0,
  bankLoan: 0,
  totalLiabilities: 0,
  ownerCapital: 0,
  retainedEarnings: 0,
  salesDeposit: 0,
  purchaseDeposit: 0,
  currentPeriodNetProfit: 0,
  totalEkuitas: 0,
  totalPassiva: 0,
  bank: [],
  asset: [],
  totalModal: 0,
  modal: [],
  loan: [],
  totalLoan: 0,
  bankLoanResult: [],
  receivablesEmployees: 0,
  invoicePaymentGiroUnpaid: 0,
  purchaseInvoicePaymentGiroUnpaid: 0
};
export type TData<T> = {
  isLoading: boolean;
  data: T;
};

const BankHistoryPage = () => {
  const classes = useStyles();
  const { history } = useRouter();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [journalTransactions, setJournalTransactions] = useState<TypeJournalTransactions>({
    isLoading: false,
    data: []
  });
  const [costBalance, setCostBalance] = useState<TypeCashBalanceModel>({
    isLoading: false,
    data: []
  });
  const [journalTransactionsId, setJournalTransactionsId] = useState<CashBalance | null>(null);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isCreateOtherExpenses, setIsCreateOrtherExpenses] = useState<boolean>(false);
  const [isUpdateOtherIncome, setIsUpdateOtherIncome] = useState<boolean>(false);
  const [isCreateTransferInternal, setIsCreateTransferInternal] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const handleOpenCreate = () => {
    setJournalTransactionsId(null);
    setAnchorEl(null);
    setIsCreate(true);
  };

  const handleOpenCreateOtherExpenses = () => {
    setJournalTransactionsId(null);
    setAnchorEl(null);
    setIsCreateOrtherExpenses(true);
  };
  const handleCloseCreate = () => {
    setSubAccountCategories([]);
    setIsCreate(false);
  };

  const handleCloseCreateTransferInternal = useCallback(() => {
    setIsCreateTransferInternal(false);
    setSubAccountCategories([]);
  }, []);
  const handleCloseCreateOtherExpenses = useCallback(() => {
    setIsCreateOrtherExpenses(false);
    setSubAccountCategories([]);
  }, []);
  const handleCloseUpdate = useCallback(() => setIsUpdate(false), []);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [type, setType] = useState<string>('');
  const [queryString, setQueryString] = useState<string>('');
  const [transaksi, setTransaksi] = useState<string>('');
  const [akun, setAkun] = useState<string>('');
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [subAccountCategories, setSubAccountCategories] = useState<AccountCategoryModel[]>([]);
  const handleCloseUpdateOtherIncome = useCallback(() => {
    setIsUpdateOtherIncome(false);
    setSubAccountCategories([]);
  }, []);
  const [financialAccounts, setFinancialAccounts] = useState<AccountCategoryModel[]>([]);

  const [isAction, setIsAction] = useState<{
    isOpen: boolean;
    isLoading: boolean;
    id: number;
    action: 'delete' | 'update' | 'detail';
    type: string;
  }>({
    isOpen: false,
    isLoading: false,
    id: 0,
    action: 'delete',
    type: ''
  });
  const [balanceSheet, setBalanceSheet] = useState<TData<BalanceSheet>>({
    isLoading: false,
    data: dummyBalanceSheet
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const fetchAccountData = useCallback(
    async endpoint => {
      try {
        const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/${endpoint}`, {
          cancelToken
        });
        setSubAccountCategories(data.data);
      } catch (error) {
        console.error(`Error fetching data for endpoint: ${endpoint}`, error);
      }
    },
    [cancelToken, ACCOUNT_CATEGORY_BASE_URL]
  );

  const getDataAccount = () => fetchAccountData('cost');

  const getFinancialAccounts = useCallback(async () => {
    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/financial`, { cancelToken });
      setFinancialAccounts(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [cancelToken, ACCOUNT_CATEGORY_BASE_URL]);

  const fetchData = useCallback(async () => {
    setCostBalance(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/financial`, { cancelToken });
      setCostBalance(prev => ({ ...prev, isLoading: false, data: data.data }));

      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [queryString, order, orderBy, startDate, endDate, currentPage]);

  const handleAction = (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail' = 'delete') => {
    setIsAction(prev => ({ ...prev, isOpen, id, action }));

    if (action === 'detail') {
      history.push(`/history-kas/${id}`);
    }
  };

  const reFetchData = () => {
    setIsCreate(false);
    setIsUpdate(false);
    fetchData();
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const getForm = async () => {
    setBalanceSheet(prev => ({ ...prev, isLoading: true }));
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setBalanceSheet(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('startDate', format(new Date('2000-01-01'), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));

      return params;
    };

    try {
      const { data } = await axios.get(`${BALANCE_SHEET_BASE_URL}/form?${getQueryParams()}`, {
        cancelToken
      });
      console.log(data);
      setBalanceSheet(prev => ({ ...prev, isLoading: false, data: data }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getForm();
    fetchData();
    getDataAccount();
    getFinancialAccounts();
  }, [fetchData, queryString]);
  return (
    <Grid>
      <Grid container direction='row' spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container direction='row' justify='space-between'></Grid>
        </Grid>
      </Grid>
      <Grid xs={12} container className={classes.container}>
        <Grid xs={12}>
          <TransactionTable balanceSheet={balanceSheet} cashBalance={costBalance} handleAction={handleAction} />
        </Grid>
        {journalTransactions.data.length > 0 && (
          <Grid xs={12} container>
            <Pagination
              count={meta.last_page}
              onChange={(event, page) => setCurrentPage(page)}
              page={meta.current_page}
              boundaryCount={2}
              variant='outlined'
              shape='rounded'
            />
          </Grid>
        )}
        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => setOpenCalendarFilter(false)}
        />
      </Grid>
    </Grid>
  );
};

export default BankHistoryPage;
