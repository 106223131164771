import { Button, CircularProgress, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import { GREEN, BLACK, WHITE } from 'constants/colors';
import React, { memo } from 'react';

const useStyles = makeStyles({
  container: {
    rowGap: '1em'
  },
  refreshButton: {
    backgroundColor: GREEN,
    color: WHITE,
    padding: '.3em',
    borderRadius: '5px',
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: BLACK
    }
  }
});

interface Props {
  dateLabel: string;
  onClick: { isLoadingDownload: boolean; handleDownload: () => void; handleRefresh: () => void };
}
const HeaderSection = ({ dateLabel, onClick }: Props) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={6}>
        <FlexBox columnGap={1} alignItems='center' alignContent='flex-start'>
          <IconButton aria-label='refresh' className={classes.refreshButton} disableRipple={false} size='small' onClick={onClick.handleRefresh}>
            <RefreshOutlined />
          </IconButton>

          <Grid item>
            <Typography variant='h6'>Laporan Laba Rugi</Typography>
            <Typography color='textSecondary'>{dateLabel}</Typography>
          </Grid>
        </FlexBox>
      </Grid>
      <Grid xs={6} container justify='flex-end' alignContent='center'>
        <Button variant='contained' onClick={onClick.handleDownload} color='primary' disabled={onClick.isLoadingDownload} size='small'>
          {onClick.isLoadingDownload ? <CircularProgress color='primary' size={20} /> : 'Download PDF'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(HeaderSection);
