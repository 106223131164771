import { Button, Container, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { Today } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import DateRangeFilter from 'components/DateRangeFilter';
import { ACCOUNT_CATEGORY_BASE_URL, COST_BALANCE_BASE_URL, JOURNAL_TRANSACTIONS_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import TransactionTable from './components/TransactionTable';
import RefreshIcon from '@material-ui/icons/Refresh';
import { GREEN, WHITE } from 'constants/colors';
import CreateTransferInternal from './components/CreateTransferInternal';
import TransactionDelete from './components/TransactionDelete';
import { Page, PaperCustom, StandardConfirmationDialog } from 'components';
import { CashBalance } from 'typings/CashBalanceModel';

export type TypeJournalTransactions = {
  isLoading: boolean;
  data: transactionJurnal[];
};

export type TypeCashBalanceModel = {
  isLoading: boolean;
  data: CashBalance[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '2em',
    marginTop: '2em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const CashBalancePage = () => {
  const classes = useStyles();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [journalTransactions, setJournalTransactions] = useState<TypeJournalTransactions>({
    isLoading: false,
    data: []
  });
  const [costBalance, setCostBalance] = useState<TypeCashBalanceModel>({
    isLoading: false,
    data: []
  });
  const [journalTransactionsId, setJournalTransactionsId] = useState<CashBalance | null>(null);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isCreateOtherExpenses, setIsCreateOrtherExpenses] = useState<boolean>(false);
  const [isUpdateOtherIncome, setIsUpdateOtherIncome] = useState<boolean>(false);
  const [isCreateTransferInternal, setIsCreateTransferInternal] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const handleOpenCreate = () => {
    setJournalTransactionsId(null);
    setAnchorEl(null);
    setIsCreate(true);
  };
  const handleOpenCreateTransferInternal = () => {
    setJournalTransactionsId(null);
    setIsCreateTransferInternal(true);
    setAnchorEl(null);
    setJournalTransactions({
      isLoading: false,
      data: []
    });
    setCostBalance({
      isLoading: false,
      data: []
    });
  };

  const handleOpenCreateOtherExpenses = () => {
    setJournalTransactionsId(null);
    setAnchorEl(null);
    setIsCreateOrtherExpenses(true);
  };
  const handleCloseCreate = () => {
    setSubAccountCategories([]);
    setIsCreate(false);
  };

  const handleCloseCreateTransferInternal = useCallback(() => {
    setIsCreateTransferInternal(false);
    setSubAccountCategories([]);
  }, []);
  const handleCloseCreateOtherExpenses = useCallback(() => {
    setIsCreateOrtherExpenses(false);
    setSubAccountCategories([]);
  }, []);
  const handleCloseUpdate = useCallback(() => setIsUpdate(false), []);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [type, setType] = useState<string>('');
  const [queryString, setQueryString] = useState<string>('');
  const [transaksi, setTransaksi] = useState<string>('');
  const [akun, setAkun] = useState<string>('');
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [subAccountCategories, setSubAccountCategories] = useState<AccountCategoryModel[]>([]);
  const handleCloseUpdateOtherIncome = useCallback(() => {
    setIsUpdateOtherIncome(false);
    setSubAccountCategories([]);
  }, []);
  const [financialAccounts, setFinancialAccounts] = useState<AccountCategoryModel[]>([]);

  const [isAction, setIsAction] = useState<{
    isOpen: boolean;
    isLoading: boolean;
    id: number;
    action: 'delete' | 'update' | 'detail';
    type: string;
  }>({
    isOpen: false,
    isLoading: false,
    id: 0,
    action: 'delete',
    type: ''
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const fetchAccountData = useCallback(
    async endpoint => {
      try {
        const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/${endpoint}`, {
          cancelToken
        });
        setSubAccountCategories(data.data);
      } catch (error) {
        console.error(`Error fetching data for endpoint: ${endpoint}`, error);
      }
    },
    [cancelToken, ACCOUNT_CATEGORY_BASE_URL]
  );

  const getDataAccount = () => fetchAccountData('cost');
  const getAccountPassiva = () => fetchAccountData('passiva');
  const getAccountTax = () => fetchAccountData('tax');
  const getAccountLoan = () => fetchAccountData('loan');
  const getAccountCapitalInvestment = () => fetchAccountData('capital-investment');
  const getAccountOtherIncome = () => fetchAccountData('other-income');
  const getTaxExpenditure = () => fetchAccountData('tax-expenditure');
  const getAssetPurchase = () => fetchAccountData('asset-purchase');
  const getInstallmentPayments = () => fetchAccountData('installment-payments');
  const getEmployeeReceivables = () => fetchAccountData('employee-receivables');
  const getDividendDistribution = () => fetchAccountData('dividend-distribution');
  const getSalesDeposit = () => fetchAccountData('sales-deposit');
  const getPurchaseDeposit = () => fetchAccountData('purchase-deposit');

  const getFinancialAccounts = useCallback(async () => {
    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/financial`, { cancelToken });
      setFinancialAccounts(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [cancelToken, ACCOUNT_CATEGORY_BASE_URL]);

  const fetchData = useCallback(async () => {
    setCostBalance(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }
      if (transaksi) {
        params.append('transaksi', transaksi);
      }
      if (akun) {
        params.append('akun', akun);
      }

      params.append('page', currentPage.toString());
      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }
      return params;
    };

    try {
      const { data } = await axios.get(`${COST_BALANCE_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setCostBalance(prev => ({ ...prev, isLoading: false, data: data.data }));
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [queryString, order, orderBy, startDate, endDate, currentPage]);

  const handleAction = (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail' = 'delete') => {
    setIsAction(prev => ({ ...prev, isOpen, id, action }));

    if (action === 'update') {
      setJournalTransactionsId(null);
      setIsCreateTransferInternal(false);
      setIsCreate(false);
      handleOnUpdate(id);
      setType(type);
      getDataAccount();
      setIsCreateTransferInternal(true);
    } else if (action === 'detail') {
      setIsDetail(true);
      handleOnUpdate(id);
    }
  };

  const handleOnUpdate = async (id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await axios.get(`${COST_BALANCE_BASE_URL}/${id}`, { cancelToken: cancelTokenSource.token });
      setJournalTransactionsId(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    fetchData();
    getDataAccount();
    getFinancialAccounts();
  }, [fetchData, queryString]);

  const reFetchData = () => {
    setIsCreate(false);
    setIsUpdate(false);
    fetchData();
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleDelete = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.delete(`${COST_BALANCE_BASE_URL}/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
      handleSnackBar(true, 'success', 'Saldo Kas berhasil dihapus.');
    } catch (error) {
      console.log(error);
    }
  };

  const onRefresh = () => {
    setOrderBy('id');
    setOrder('desc');
    setQueryString('');
    setCurrentPage(1);
    setStartDate(format(new Date(startDate), 'yyyy-MM-dd'));
    setEndDate(format(new Date(endDate), 'yyyy-MM-dd'));
  };

  return (
    <Page title='Modal Awal'>
      <Container>
        <PaperCustom>
          <Grid>
            <Grid container direction='row' spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container direction='row' justify='space-between'>
                  <Grid item xl={7} lg={7} md={12} sm={12} xs={12} container alignItems='center'>
                    <Tooltip title='Memuat Ulang'>
                      <Button size='small' onClick={onRefresh} color='inherit' className={classes.refresh}>
                        <RefreshIcon fontSize='small' />
                      </Button>
                    </Tooltip>
                    <Typography style={{ fontSize: '1rem', fontWeight: 400, marginLeft: '15px' }}>
                      {`Menampilkan ${meta.total || 0} Transaksi (${meta.from || 0} - ${meta.to || 0} dari ${meta.total || 0})`}

                      <Typography color='primary'>
                        Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
                    <Grid item>
                      <Tooltip title='Calendar filter' placement='top'>
                        <IconButton color='primary' aria-label='filter date' component='span' onClick={handleCalendarFilterClick}>
                          <Today />
                        </IconButton>
                      </Tooltip>

                      <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleOpenCreateTransferInternal}>
                        Tambah Saldo Kas
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} container className={classes.container}>
              <Grid xs={12}>
                <TransactionTable
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  setTransaksi={setTransaksi}
                  setAkun={setAkun}
                  akun={akun}
                  transaksi={transaksi}
                  journalTransactions={journalTransactions}
                  cashBalance={costBalance}
                  handleAction={handleAction}
                />
              </Grid>
              {journalTransactions.data.length > 0 && (
                <Grid xs={12} container>
                  <Pagination
                    count={meta.last_page}
                    onChange={(event, page) => setCurrentPage(page)}
                    page={meta.current_page}
                    boundaryCount={2}
                    variant='outlined'
                    shape='rounded'
                  />
                </Grid>
              )}
              <DateRangeFilter
                openCalendarFilter={openCalendarFilter}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleClose={() => setOpenCalendarFilter(false)}
              />
            </Grid>

            <CreateTransferInternal
              financialAccounts={financialAccounts}
              subAccountCategories={subAccountCategories}
              open={isCreateTransferInternal}
              journalTransactionsId={journalTransactionsId}
              handleCloseCreate={handleCloseCreateTransferInternal}
              reFetchData={reFetchData}
              handleSnackBar={handleSnackBar}
            />

            <TransactionDelete
              open={isAction.isOpen && isAction.action.includes('delete')}
              handleClose={() => handleAction(false, 0, 'delete')}
              handleSubmit={handleDelete}
              isLoading={isAction.isLoading}
            />

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={handleCloseSnackbar}
              onConfirm={handleCloseSnackbar}
              noCancelButton={true}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CashBalancePage;
